import { setMegaMenu } from 'redux/modules/page/actions/set-mega-menu';
import { setMegaMenuActiveLevel } from 'redux/modules/page/actions/set-mega-menu-active-level';
import { setMegaMenuState } from 'redux/modules/page/actions/set-mega-menu-state';

export const navigateFromMegaMenu =
  (level, name, id = null) =>
  dispatch => {
    dispatch(setMegaMenu(level, id, name));
    dispatch(setMegaMenuActiveLevel(-1));
    dispatch(setMegaMenuState(false));
  };
