import React from 'react';
import { useAuthentication } from '@johnlewispartnership/wtr-website-authentication/dist/context';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/TextLink';
import { handleLoginWithRedirect } from 'utils/login';
import { useTrolley } from 'contexts/Trolley';
import config from 'config';
import { getFeatureFlags } from 'utils/featureFlags';
import { urls } from 'constants/urls';
import { ShoppingSummary } from './ShoppingSummary';
import styles from './ShoppingSummaryContainer.module.scss';

const ShoppingSummaryContainer = () => {
  const { state: authState } = useAuthentication();
  const { state: trolleyState } = useTrolley();
  const { identity_enableOAuth2Web: OAuth2Enabled } = getFeatureFlags();

  const isAuthEnabled = config.services.tokenClient.enabled && OAuth2Enabled;

  // TODO - Move shouldRenderLoggedInVersion to a shared location to avoid repetition
  const shouldRenderLoggedInVersion =
    isAuthEnabled &&
    ((authState.hasTokenSession && !authState.initialised) ||
      (authState.initialised && authState.isLoggedIn));

  const SignInOrTrolley = shouldRenderLoggedInVersion ? (
    <ShoppingSummary
      loadingStatus={trolleyState.loading}
      totalEstimatedCost={trolleyState.totalEstimatedCost}
      totalNumberOfItems={trolleyState.totalNumberOfItems}
      hasTrolleyError={!!trolleyState.error}
      testIdSuffix="mobile"
    />
  ) : (
    <TextLink
      data-testid="mobileHeaderSigninLink"
      href={urls.login}
      onClick={handleLoginWithRedirect}
      underline="never"
    >
      Sign in
    </TextLink>
  );

  return (
    <>
      <div className={styles.trolley} data-testid="shopping-summary-container-desktop">
        <ShoppingSummary
          hasTrolleyError={!!trolleyState.error}
          loadingStatus={trolleyState.loading && shouldRenderLoggedInVersion}
          totalEstimatedCost={trolleyState.totalEstimatedCost}
          totalNumberOfItems={trolleyState.totalNumberOfItems}
        />
      </div>
      <div className={styles.signInOrTrolley} data-testid="shopping-summary-container-mobile">
        {SignInOrTrolley}
      </div>
    </>
  );
};

export default ShoppingSummaryContainer;
