import { connect } from 'react-redux';
import { setMegaMenuActiveLevel } from 'redux/modules/page/actions/set-mega-menu-active-level';
import { setMegaMenuStart } from 'redux/modules/page/actions/set-mega-menu-start';
import { setMegaMenuState } from 'redux/modules/page/actions/set-mega-menu-state';
import { megaMenuIsOpen } from 'redux/modules/page/selectors/mega-menu';
import DropDownNav from './DropdownNav';

export default connect(
  state => ({
    isOpen: megaMenuIsOpen(state),
  }),
  dispatch => ({
    onOpen: menuRoot => {
      dispatch(setMegaMenuStart(menuRoot));
      dispatch(setMegaMenuState(true));
    },
    onClose: () => {
      dispatch(setMegaMenuActiveLevel(-1));
      dispatch(setMegaMenuState(false));
    },
  }),
)(DropDownNav);
