import { print } from 'graphql/language/printer';
import { ServiceClient } from '@johnlewispartnership/wtr-website-api-client';
import config from 'config';
import { logApiError, logApiInfo } from 'logger';
import getApiBaseUrl from 'utils/getApiBaseUrl';
import { GetTrolleyResult, GraphQuery, GetTrolleyResponseData } from 'services/graphql/types';
import getTrolley from './queries/getTrolley.graphql';

const notFoundErrorCodes = [404, 400];

class GraphQLServiceClient extends ServiceClient {
  async getTrolley(orderId: string, authHeader: string): Promise<GetTrolleyResult> {
    const apiPath = 'graph/live';
    const response = await this.httpClient.post<GraphQuery, GetTrolleyResponseData>(
      apiPath,
      {
        query: `${print(getTrolley)}`,
        variables: {
          orderId,
        },
      },
      {
        headers: {
          Authorization: authHeader,
          contentType: 'application/json',
        },
      },
    );

    const { duration } = response;
    logApiInfo({
      payload: {
        duration,
        service: 'graphql',
        url: `${getApiBaseUrl(config.services.graphql.path)}/${apiPath}`,
        message: `GraphQL API request took ${duration}ms`,
        httpStatus: `${response.status}`, // TODO add statusText (see Logging Standards)
        httpStatusCode: response.status,
      },
    });

    const { trolley, failures, slotChangeable } = response.data?.data?.getTrolley ?? {};
    const { totalEstimatedCost, trolleyItemCounts } = trolley?.trolleyTotals ?? {};
    const { hardConflicts = 0, noConflicts = 0, softConflicts = 0 } = trolleyItemCounts ?? {};
    const totalNumberOfItems = hardConflicts + noConflicts + softConflicts;

    let error;
    if (response.error) {
      const isNotFoundError = notFoundErrorCodes.includes(response.status || -1);
      error = {
        message: response.error.message,
      };

      if (!isNotFoundError) {
        logApiError({
          payload: {
            service: 'graphql',
            url: `${getApiBaseUrl(config.services.graphql.path)}/${apiPath}`,
            message: 'Error loading trolley',
            errorDetails: error,
            duration,
            httpStatus: `${response.status}`, // TODO add statusText (see Logging Standards)
            httpStatusCode: response.status,
          },
        });
      }
    } else if (failures?.length) {
      const { message, type } = failures[0];
      const isNotFoundError = notFoundErrorCodes.includes(Number(type));

      error = {
        message,
        type,
      };

      if (!isNotFoundError) {
        logApiError({
          payload: {
            service: 'graphql',
            duration,
            url: `${getApiBaseUrl(config.services.graphql.path)}/${apiPath}`,
            message: 'Error loading trolley (graphQL failure)',
            httpStatus: `${response.status}`, // TODO add statusText (see Logging Standards)
            httpStatusCode: response.status,
            errorDetails: error,
          },
        });
      }
    }

    return {
      trolley,
      error,
      slotChangeable: !!slotChangeable,
      totalEstimatedCost: totalEstimatedCost?.amount || 0,
      totalNumberOfItems,
    };
  }
}

export default new GraphQLServiceClient(getApiBaseUrl(config.services.graphql.path));

export type { GraphQLServiceClient, GetTrolleyResponseData };
