import { format } from 'date-fns';
import { formatSlotTime } from 'utils/format-slot-time';

const formatSlotButtonText = ({
  isSlotBooked,
  shortTime = false,
  showEndTime = false,
  slotEndDateTime,
  slotStartDateTime,
} = {}) => {
  if (isSlotBooked && slotStartDateTime) {
    let formattedData = `${format(slotStartDateTime, 'eee')} ${format(
      slotStartDateTime,
      'dd',
    )} ${format(slotStartDateTime, 'MMM')}, ${formatSlotTime(slotStartDateTime).toLowerCase()}${
      showEndTime && slotEndDateTime ? ` - ${formatSlotTime(slotEndDateTime).toLowerCase()}` : ''
    }`;

    if (shortTime) {
      formattedData = `${format(slotStartDateTime, 'eee')} ${format(
        slotStartDateTime,
        'dd',
      )}, ${formatSlotTime(slotStartDateTime).toLowerCase()}`;
    }

    return formattedData;
  }
  return 'Book a slot';
};

const formatSlotButtonScreenReaderLabel = ({
  isSlotBooked,
  slotEndDateTime,
  slotStartDateTime,
  slotType,
} = {}) => {
  if (isSlotBooked && slotStartDateTime && slotEndDateTime && slotType) {
    const slotTypeText = slotType.includes('COLLECTION') ? 'Collection' : 'Delivery';
    const longDateText = `${format(slotStartDateTime, 'eeee')} ${format(
      slotStartDateTime,
      'dd',
    )} ${format(slotStartDateTime, 'MMMM')}`;
    const longTimeText = `${formatSlotTime(slotStartDateTime)}-${formatSlotTime(slotEndDateTime)}`;

    return `${slotTypeText} slot booked for ${longDateText} ${longTimeText}. Change slot.`;
  }
  return 'Book a slot';
};

export { formatSlotButtonText, formatSlotButtonScreenReaderLabel };
