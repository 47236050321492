import { connect } from 'react-redux';

import autoSuggestClear from 'redux/modules/auto-suggest/actions/auto-suggest-clear';
import autoSuggestSearch from 'redux/modules/auto-suggest/actions/auto-suggest-search';
import { getAutoSuggestActive } from 'redux/modules/auto-suggest/selectors';
// import { getSearchAndBrowseLoading } from 'redux/modules/search-and-browse/selectors';
// import { getCustomerSearchTerm } from 'redux/modules/search-and-browse/selectors/search';
import { updateSearchTerm } from 'redux/modules/search/actions/update-search-term';
import setMobileOverlay from 'redux/modules/page/actions/set-mobile-overlay';
import MobileOverlay from './MobileOverlay';

export default connect(
  state => ({
    // TODO: Use the actual values from redux when Search is implemented: https://www.jlpit.com/jira/browse/WPIP-48065
    autoSuggestActive: getAutoSuggestActive(state),
    // initialValue: getCustomerSearchTerm(state),
    initialValue: '',
    // loading: getSearchAndBrowseLoading(state),
    loading: false,
  }),
  {
    onChange: autoSuggestSearch,
    onClear: autoSuggestClear,
    onSubmit: updateSearchTerm,
    onClose: setMobileOverlay,
  },
)(MobileOverlay);
