// TODO evaluate if animations can be done in css

const easeIn = power => t => t ** power;
const easeOut = power => t => 1 - Math.abs((t - 1) ** power);
const easeInOut = power => t =>
  t < 0.5 ? easeIn(power)(t * 2) / 2 : easeOut(power)(t * 2 - 1) / 2 + 0.5;

export const EASING = {
  linear: easeInOut(1),
  inQuad: easeIn(2),
  outQuad: easeOut(2),
  inOutQuad: easeInOut(2),
  inCubic: easeIn(3),
  outCubic: easeOut(3),
  inOutCubic: easeInOut(3),
  inQuart: easeIn(4),
  outQuart: easeOut(4),
  inOutQuart: easeInOut(4),
  inQuint: easeIn(5),
  outQuint: easeOut(5),
  inOutQuint: easeInOut(5),
};

export const animate = ({
  actuator,
  duration = 1000,
  easing = EASING.inOutCubic,
  end = 100,
  start = 0,
  target,
}) => {
  const range = end - start;
  let startTime = null;

  const nextFrame = done =>
    window.requestAnimationFrame(time => {
      if (startTime === null) startTime = time;

      const elapsed = time - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const value = Math.round(start + range * easing(progress));

      actuator(value, target);

      if (progress < 1) nextFrame(done);
      else done();
    });

  // eslint-disable-next-line no-promise-executor-return
  return new Promise(resolve => nextFrame(resolve));
};
