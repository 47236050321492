import React, { FC } from 'react';
import { User } from '@johnlewispartnership/wtr-ingredients/dist/foundations/icons';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/TextLink';
import { handleLoginWithRedirect } from 'utils/login';
import { urls } from 'constants/urls';

import styles from './SignIn.module.scss';

type SignInProps = {
  className: string;
  loggedIn: boolean;
};

export const SignIn: FC<SignInProps> = ({ className, loggedIn }) =>
  !loggedIn ? (
    <>
      <TextLink
        className={className}
        href={urls.login}
        onClick={handleLoginWithRedirect}
        underline="never"
      >
        <User className={styles.signInIcon} />
        Sign in
      </TextLink>
      <TextLink className={className} href={urls.registration} underline="never">
        Register
      </TextLink>
    </>
  ) : null;
