import { AUTOSUGGEST_FULFILLED } from 'redux/modules/auto-suggest/actions/types';

const autoSuggestSearch = searchTerm => ({
  type: AUTOSUGGEST_FULFILLED,
  result: {
    terms: [],
    empty: true,
  },
  payload: {
    terms: [],
    empty: true,
  },
  searchTerm,
});

export default autoSuggestSearch;
