/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ScreenReaderAnnouncement from 'components/ScreenReaderAnnouncement';
import { KEY_DOWN, KEY_ENTER, KEY_UP, KEY_TAB } from 'constants/keys';
import { refType } from 'constants/types/ref';
import termSuggestService from 'services/termSuggest';
import Highlight from './Highlight';
import styles from './AutoComplete.scss';

export const AutoComplete = ({ inputRef, active, onClear, onSelectItem, inline, searchTerm }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  const handleItemClick = suggestion => {
    onSelectItem(suggestion);
  };

  const handleKeyDown = event => {
    if (!active) return;

    switch (event.keyCode) {
      case KEY_DOWN: {
        if (selectedIndex === null) {
          setSelectedIndex(0);
          break;
        }

        const newSelectedIndex = selectedIndex + 1;
        if (newSelectedIndex < suggestions.length) {
          setSelectedIndex(newSelectedIndex);
        }
        break;
      }
      case KEY_UP: {
        event.preventDefault();
        const newSelectedIndex = selectedIndex - 1;
        if (newSelectedIndex >= 0) {
          setSelectedIndex(newSelectedIndex);
        } else {
          setSelectedIndex(null);
        }
        break;
      }
      case KEY_ENTER: {
        if (selectedIndex !== null) {
          handleItemClick(suggestions[selectedIndex]);
        }
        break;
      }
      case KEY_TAB: {
        onClear();
        setSelectedIndex(null);
        break;
      }

      default: {
        setSelectedIndex(null);
      }
    }
  };

  useEffect(() => {
    const currentInputRef = inputRef?.current;
    if (currentInputRef?.addEventListener) {
      currentInputRef.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (currentInputRef?.removeEventListener) {
        currentInputRef.removeEventListener('keydown', handleKeyDown);
      }
    };
  });

  const fetchSuggestions = useMemo(
    () =>
      debounce(
        async userSearchTerm => {
          if (userSearchTerm.length) {
            const suggestionsResponse = await termSuggestService.getSuggestions(userSearchTerm);
            setSuggestions(suggestionsResponse);
          }
        },
        300,
        { leading: true, maxWait: 600 },
      ),
    [setSuggestions],
  );

  useEffect(() => {
    fetchSuggestions(searchTerm);
  }, [fetchSuggestions, searchTerm]);

  if (!active || suggestions.length < 1) return null;

  return (
    <section
      className={classNames(styles.autoComplete, {
        [styles.inline]: inline,
      })}
      data-testid="autocomplete"
    >
      <ul className={styles.suggestions} id="autocomplete-listbox" role="listbox">
        {suggestions.map((suggestion, index) => (
          <li
            aria-selected={index === selectedIndex}
            className={classNames(styles.suggestion, {
              [styles.selected]: index === selectedIndex,
            })}
            data-testid="suggestion"
            key={suggestion}
            id="autocomplete-suggestion"
            onClick={() => handleItemClick(suggestion)}
            role="option"
          >
            <Highlight match={searchTerm} string={suggestion} />
          </li>
        ))}
      </ul>
      <ScreenReaderAnnouncement message={`${suggestions.length} suggestions available.`} />
    </section>
  );
};

AutoComplete.displayName = 'AutoComplete';

AutoComplete.propTypes = {
  active: PropTypes.bool,
  inline: PropTypes.bool,
  inputRef: refType,
  onClear: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
};

AutoComplete.defaultProps = {
  active: false,
  inline: false,
  inputRef: null,
  searchTerm: '',
};
