import React from 'react';
import {
  NavLinksContainer,
  NavLink,
} from '@johnlewispartnership/wtr-ingredients/dist/ingredients/NavLinks';

import VerticalTextLinks, { VerticalLink } from 'cms-components/VerticalTextLinks';
import transformAemPath from './cms-path-to-url';
import styles from './index.module.scss';

type NavLinkProps = {
  url: string;
  text: string;
  newWindow: boolean;
};

export type NavigationLinksComponentProps = {
  componentId: string;
  navigationLinks: NavLinkProps[];
  styleIds: string[];
};

const NavigationLinks = ({
  componentId,
  navigationLinks,
  styleIds,
}: NavigationLinksComponentProps) => {
  const isVerticalTextLinks = styleIds?.includes('vertical-text-links');

  if (!navigationLinks?.length) return null;

  const links: VerticalLink[] = navigationLinks.map(navLink => {
    const url = transformAemPath(navLink.url);

    // NOTE: we have diverged from the monolith version in that we are
    // *always* using an anchor tag rather than a React Router link.
    // Because in the MFE world we are navigating across applications, so
    // we need full page loads rather than SPA navigations.
    // We will need to revisit this when we have multiple pages within MFEs and
    // if/when we wish to support SPA navigations with these nav links.
    // But by that time we should have the Content micro-frontend service, so
    // we'll need to solve it there, since this code should be getting deleted.
    return {
      label: navLink.text,
      component: 'a',
      href: url,
      ...(navLink.newWindow && { target: '_blank' }),
    };
  });

  if (isVerticalTextLinks) {
    return <VerticalTextLinks links={links} />;
  }

  return (
    <div className={styles.before}>
      <NavLinksContainer
        linkElement="a"
        id={componentId}
        backgroundColor="scallop"
        data-testid="nav-links"
      >
        {links.map(({ label, ...linkProps }) => (
          <NavLink {...linkProps} key={label}>
            {label}
          </NavLink>
        ))}
      </NavLinksContainer>
    </div>
  );
};

export default NavigationLinks;
