import React, { useCallback } from 'react';
import { bool, func } from 'prop-types';
import { Document, HeartInactive } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { useAnalytics } from '@johnlewispartnership/wtr-website-analytics';
import { urls } from 'constants/urls';
import AnchorLink from 'components/AnchorLink';
import SignIn from 'components/AuthenticationAction/SignIn';
import SeasonalMenu from '../SeasonalMenu';

// TODO: Implement Monetate integration
// import { useExperiments } from 'components/Experiment/useExperiments';
// import { NAV_LINKS_TRENDING } from 'constants/monetateExperiments';

import MyAccount from './MyAccount/MyAccount';

import styles from './LinksBar.scss';

const LinksBar = ({
  showContactAddressNotPresentNotification,
  currentPageIsGroceriesHomePage,
  isLoggedIn,
  onToggleSeasonalMenu,
  isSeasonalMenuOpen,
}) => {
  const { trackEvent } = useAnalytics();

  const pushMenuClick = useCallback(
    buttonLabel => {
      trackEvent({
        event: 'click_megamenu',
        mega_menu: {
          button_clicked: buttonLabel,
        },
      });
    },
    [trackEvent],
  );

  const unauthenticatedAccountLinks = (
    <nav
      className={styles.secondaryLinks}
      data-test="account-link-login"
      aria-label="Sign in and register"
    >
      <SignIn className={styles.links} />
    </nav>
  );

  const authenticatedAccountLinks = (
    <nav aria-label="Account links" className={styles.secondaryLinks}>
      <AnchorLink
        id="site-header-favourites"
        to={urls.favourites}
        className={styles.links}
        onClick={() => pushMenuClick('Favourites')}
      >
        <HeartInactive className={styles.favouritesIcon} size="medium" />
        Favourites
      </AnchorLink>
      <AnchorLink
        id="site-header-my-orders"
        to={urls.myOrdersPage}
        className={styles.links}
        data-test="site-header-my-orders"
        onClick={() => pushMenuClick('My orders')}
      >
        <Document className={styles.ordersIcon} />
        My orders
      </AnchorLink>
      <MyAccount
        currentPageIsGroceriesHomePage={currentPageIsGroceriesHomePage}
        showContactAddressNotPresentNotification={showContactAddressNotPresentNotification}
      />
    </nav>
  );

  // const { getDecisionById } = useExperiments();

  // const isExperiment =
  //   getDecisionById(NAV_LINKS_TRENDING.experiment).variant ===
  //   NAV_LINKS_TRENDING.variant.showTrending;
  // const isExperiment = false;

  const accountLinks = !isLoggedIn ? unauthenticatedAccountLinks : authenticatedAccountLinks;

  return (
    <div className={styles.siteHeaderLinks} data-testid="site-header-links">
      <nav className={styles.primaryLinks} aria-label="Main navigation">
        <SeasonalMenu
          onToggleSeasonalMenu={onToggleSeasonalMenu}
          isSeasonalMenuOpen={isSeasonalMenuOpen}
        />
        <AnchorLink
          to={urls.offers}
          className={styles.offers}
          id="site-header-offers"
          data-testid="site-header-offers"
          onClick={() => pushMenuClick('Offers')}
        >
          Offers
        </AnchorLink>
        <AnchorLink
          to={urls.entertainingHome}
          className={styles.links}
          data-testid="site-header-entertaining"
          id="site-header-entertaining"
          onClick={() => pushMenuClick('Entertaining')}
        >
          Entertaining
        </AnchorLink>
        <AnchorLink
          id="site-header-new"
          data-testid="site-header-new"
          className={styles.links}
          to={urls.new}
          onClick={() => pushMenuClick('New')}
        >
          {/* {isExperiment ? 'Trending' : 'New'} */}
          New
        </AnchorLink>
        <AnchorLink
          id="site-header-recipes"
          data-testid="site-header-recipes"
          className={styles.links}
          to="/ecom/recipes"
          onClick={() => pushMenuClick('Recipes')}
        >
          Recipes
        </AnchorLink>
        <AnchorLink
          id="site-header-inspiration"
          data-testid="site-header-inspiration"
          className={styles.links}
          to={urls.inspiration}
          onClick={() => pushMenuClick('Inspiration')}
        >
          Inspiration
        </AnchorLink>
      </nav>
      {accountLinks}
    </div>
  );
};

LinksBar.displayName = 'LinksBar';

LinksBar.propTypes = {
  showContactAddressNotPresentNotification: bool,
  currentPageIsGroceriesHomePage: bool,
  isLoggedIn: bool,
  isSeasonalMenuOpen: bool,
  onToggleSeasonalMenu: func.isRequired,
};

LinksBar.defaultProps = {
  showContactAddressNotPresentNotification: false,
  currentPageIsGroceriesHomePage: false,
  isLoggedIn: false,
  isSeasonalMenuOpen: false,
};

export default LinksBar;
