export const formatMenuClickEvent = (
  navigation: string,
  additionalData: Record<string, any> = {},
) => ({
  event: 'click_megamenu',
  mega_menu: {
    button_clicked: navigation,
    ...additionalData,
  },
});
