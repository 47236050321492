import { useRef, useCallback } from 'react';
import { useAnalytics } from '@johnlewispartnership/wtr-website-analytics';
import { formatGroceriesMenuNavigation } from 'utils/format';
import { KEY_ENTER, KEY_RIGHT } from 'constants/keys';
import { useMenuFocus } from 'components/MegaMenu/MenuFocusProvider';
import { formatMenuClickEvent } from 'utils/tracking';

export const useMenuItemNavigation = ({
  display = true,
  id,
  isLink = true,
  hasDescendants,
  level,
  menus,
  name,
  // eslint-disable-next-line no-unused-vars
  onClick = event => {},
  closeMegaMenu,
  newWindow,
}) => {
  const ref = useRef();
  const { trackEvent } = useAnalytics();
  const { handleClick, handleKeyDown, tabIndex } = useMenuFocus(ref, { id, skip: !display });

  const navigation =
    level > 0
      ? `$${formatGroceriesMenuNavigation(menus.flatMap(item => item.name))}|G${level + 1}:${name}`
      : `${name}`;

  const click = useCallback(
    event => {
      onClick(event);
      if (newWindow) return;
      if (isLink) {
        if (
          ['Book slot', 'Find a store', 'Customer Service', 'Home'].every(item => item !== name)
        ) {
          trackEvent(formatMenuClickEvent(navigation));
        }
        closeMegaMenu();
      } else {
        if (name === 'Groceries' || name === 'Christmas') {
          trackEvent(formatMenuClickEvent(name));
        }
        event.preventDefault();
      }
    },
    [closeMegaMenu, isLink, name, navigation, newWindow, onClick, trackEvent],
  );

  const handleClickItem = useCallback(
    event => {
      handleClick(event);
      click(event);
    },
    [click, handleClick],
  );

  const handleKeyDownItem = useCallback(
    event => {
      if (event.keyCode === KEY_RIGHT && !hasDescendants) return;
      if (event.keyCode === KEY_ENTER) click(event);
      handleKeyDown(event);
    },
    [click, handleKeyDown, hasDescendants],
  );

  return {
    handleClickItem,
    handleKeyDownItem,
    ref,
    tabIndex,
  };
};
