import { connect } from 'react-redux';

import { GROCERIES_OFFERS, SEASONAL, SLOT_BUTTON } from 'constants/categoryIds';

import { getCategoryById } from 'redux/modules/taxonomy/selectors';
import { getMegaMenuMenus, getMegaMenuActiveLevel } from 'redux/modules/page/selectors/mega-menu';
import { getMegaMenuHistory, getPathForMegaMenuItemById } from 'redux/modules/mega-menu/selectors';

import closeMegaMenu from 'redux/modules/mega-menu/actions/close-mega-menu';

import { MenuLink } from './MenuLink';

// TODO change this once the auth part is implemented and remove istanbul ignore
const hasBookedSlot = () => false;
/* istanbul ignore next */
const getEditSlotUrl = () => '';

export default connect(
  (state, props) => {
    const { generated, id, maxMenus, offer = false, seasonalMenuTitle } = props;
    let {
      emptyOfProducts = false,
      hiddenInNav = false,
      categoryIds = [],
      iconName: icon,
      name,
      url,
    } = props;
    let isOffer = false;

    if (!generated) {
      const category = getCategoryById(state, id) || {};

      emptyOfProducts = category.emptyOfProducts; // eslint-disable-line prefer-destructuring
      categoryIds = category.categoryIds || [];
      hiddenInNav = category.hiddenInNav;
      icon = category.iconName;
      isOffer = category.offer || false;
      name = category.name; // eslint-disable-line prefer-destructuring
      url = category.url; // eslint-disable-line prefer-destructuring
    }

    if (id === GROCERIES_OFFERS) {
      name = 'Groceries OFFERS';
    }

    // TODO state needs passing through to hasBookedSlot once ported over
    if (id === SLOT_BUTTON && !hasBookedSlot()) {
      name = 'Book Slot';
    }

    // TODO test this once the auth part is implemented
    // TODO state needs passing through to hasBookedSlot & getEditSlotUrl once ported over
    /* istanbul ignore next */
    if (id === SLOT_BUTTON && hasBookedSlot()) {
      icon = 'CalendarBooked';
      name = 'Change Slot';
      url = getEditSlotUrl();
    }

    if (id === SEASONAL && seasonalMenuTitle) {
      name = seasonalMenuTitle;
    }

    return {
      display: !emptyOfProducts && !hiddenInNav,
      hasDescendants: categoryIds.length > 0 && getMegaMenuHistory(state).length < maxMenus,
      icon,
      name,
      menus: getMegaMenuMenus(state),
      level: getMegaMenuActiveLevel(state),
      offer: offer || isOffer,
      path: url ?? getPathForMegaMenuItemById(state, id),
    };
  },
  {
    closeMegaMenu,
  },
)(MenuLink);
