import React from 'react';
import { bool, number, string } from 'prop-types';

import Spinner from '@johnlewispartnership/wtr-ingredients/dist/Spinner';
import TrolleySize from 'components/Trolley/TrolleySize';
import styles from './TrolleyButtonContents.module.scss';

export const TrolleyButtonContents = ({
  estimatedCost,
  loadingStatus,
  totalNumberOfItems,
  hasTrolleyError,
  testIdSuffix,
}) => (
  <div className={styles.buttonWrapper} data-trolley-loading={loadingStatus} aria-hidden="true">
    {loadingStatus && (
      <div className={styles.spinnerOverlay}>
        <Spinner isActive size="small" data-testid="spinner" />
      </div>
    )}
    <span className={styles.trolleySizeWrapper}>
      <TrolleySize
        size={totalNumberOfItems}
        dataTestId={`shopping-summary-total-item${testIdSuffix ? `-${testIdSuffix}` : ''}`}
      />
    </span>
    <span className={styles.totalText}>Total</span>
    <span
      className={styles.pricing}
      data-testid={`total-price-overview${testIdSuffix ? `-${testIdSuffix}` : ''}`}
    >
      {hasTrolleyError ? 'Trolley' : estimatedCost}
    </span>
  </div>
);

TrolleyButtonContents.propTypes = {
  estimatedCost: string,
  loadingStatus: bool,
  totalNumberOfItems: number,
  hasTrolleyError: bool,
  testIdSuffix: string,
};

TrolleyButtonContents.defaultProps = {
  estimatedCost: '',
  loadingStatus: false,
  totalNumberOfItems: 0,
  hasTrolleyError: false,
  testIdSuffix: '',
};
