import PropTypes from 'prop-types';
import React from 'react';

import styles from './ScreenReaderAnnouncement.module.scss';

const ScreenReaderAnnouncement = ({ polite, message }) => (
  <div className={styles.srOnly} data-testid="sr" aria-live={polite ? 'polite' : 'assertive'}>
    {message}
  </div>
);
ScreenReaderAnnouncement.propTypes = {
  polite: PropTypes.bool,
  message: PropTypes.string.isRequired,
};

ScreenReaderAnnouncement.defaultProps = {
  polite: false,
};

export default ScreenReaderAnnouncement;
