import { ServiceClient } from '@johnlewispartnership/wtr-website-api-client';
import config from 'config';
import { logApiError, logApiInfo } from 'logger';
import getApiBaseUrl from 'utils/getApiBaseUrl';

type GetSuggestionsResult = [string] | [];
type GetSuggestionsResponseData = {
  empty: boolean;
  terms: [string];
};

const termSuggestApiUrl = getApiBaseUrl(config.services.termSuggest.path);

class TermSuggestServiceClient extends ServiceClient {
  async getSuggestions(term?: string): Promise<GetSuggestionsResult> {
    const response = await this.httpClient.get<GetSuggestionsResponseData>(`?term=${term}`);

    if (!response.error) {
      logApiInfo({
        payload: {
          duration: response.duration,
          service: 'termSuggest',
          url: termSuggestApiUrl,
          message: `Search term suggestion loaded, request took ${response.duration}ms`,
          httpStatus: String(response.status),
          httpStatusCode: response.status,
        },
      });
    } else {
      logApiError({
        payload: {
          duration: response.duration,
          service: 'termSuggest',
          url: termSuggestApiUrl,
          message: `Error loading search term suggestions: ${response.error.message}`,
          httpStatus: String(response.status),
          httpStatusCode: response.status,
          errorDetails: response.error,
        },
      });
    }

    return response.data?.terms || [];
  }
}

export default new TermSuggestServiceClient(termSuggestApiUrl);
