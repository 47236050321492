import React, { ForwardedRef, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import { TextLink } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/TextLink';

interface AnchorLinkProps extends React.ComponentProps<'link'> {
  displayAsButton?: boolean | { secondary?: boolean; blockButton?: boolean };
  isFocused?: boolean;
  inNewTab?: boolean;
  to: string | { pathname: string; search: string; hash: string; state: object };
}

const AnchorLink = forwardRef(
  (
    { children, displayAsButton, isFocused, inNewTab, to, ...anchorProps }: AnchorLinkProps,
    ref,
  ) => {
    const innerRef = useRef<HTMLAnchorElement | null>(null);

    useImperativeHandle(ref, () => innerRef.current, []);

    const callbackRef = useCallback(
      (element: HTMLAnchorElement | null) => {
        if (element) {
          innerRef.current = element;
          if (isFocused) {
            element.focus();
          }
        }
      },
      [isFocused],
    );

    // TODO figure out this part of styling AnchorLink as a Button
    // const styleNames = classNames({
    //   [className]: !!className,
    //   [buttonStyle.primary]: displayAsButton && !displayAsButton.secondary,
    //   [buttonStyle.secondary]: displayAsButton && displayAsButton.secondary,
    //   [buttonStyle.blockButton]: displayAsButton && displayAsButton.blockButton,
    // });

    const url =
      typeof to === 'string'
        ? to
        : `${(to && to.pathname) || ''}${(to && to.search) || ''}${(to && to.hash) || ''}`;

    return (
      <TextLink
        {...anchorProps}
        underline="hover"
        href={url}
        ref={callbackRef as ForwardedRef<unknown> | undefined}
        target={inNewTab ? '_blank' : undefined}
      >
        {children}
      </TextLink>
    );
  },
);

AnchorLink.displayName = 'AnchorLink';

export { AnchorLink };
