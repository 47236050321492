import React from 'react';

import { urls } from 'constants/urls';

import Logo from 'components/HeaderLogo/Logo';
import styles from './index.module.scss';

export interface HeaderLogoProps {
  small?: boolean;
}

const HeaderLogo = ({ small = false }: HeaderLogoProps) => (
  <div className={styles.wrapper}>
    <a className={styles.link} href={urls.groceriesHome}>
      <Logo small={small} />
    </a>
  </div>
);

HeaderLogo.displayName = 'HeaderLogo';

export default HeaderLogo;
