import React, { ReactNode, FC } from 'react';
import { CookieConsentContext, CookieConsentContextType } from '.';

type CookieConsentProviderProps = {
  children: ReactNode;
  cookieConsent: CookieConsentContextType;
};

const CookieConsentProvider: FC<CookieConsentProviderProps> = ({ cookieConsent, children }) => (
  <CookieConsentContext.Provider value={cookieConsent}>{children}</CookieConsentContext.Provider>
);

export default CookieConsentProvider;
