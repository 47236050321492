import React from 'react';
import RichText from 'cms-components/RichText';
import NavigationLinks from 'cms-components/NavigationLinks';
import {
  NAVIGATION_LINK,
  EXPERIENCE_FRAGMENT_REFERENCE,
  RICH_TEXT,
  CONTAINER,
  RESPONSIVE_GRID,
  IMAGE,
} from 'constants/aem-component-names';

type SeasonalMenuExperienceFragmentProps = {
  content: ExperienceFragmentLocationData[];
};

export type ExperienceFragmentLocationData = {
  resourceType?: string;
  childComponents?: ExperienceFragmentLocationData[];
  nodeName?: string;
  title?: string;
  subtitle?: string;
  linkPath?: string;
  uniqueId: string;
  elementId?: string;
  [key: string]: unknown;
};

// This is a completely temporary hacky content map specifically for the Seasonal Menu!
// This will be removed as part of Content MFE implementation

const containerComponent = (component: ExperienceFragmentLocationData): React.ReactNode | null => {
  const Component = component.linkPath ? 'a' : 'section';

  const mappedComponents = component?.childComponents?.map((containerChildComponent: any) => {
    if (containerChildComponent.resourceType === RICH_TEXT) {
      return (
        <RichText
          position=""
          data={{ ...containerChildComponent, noMargin: true }}
          key={containerChildComponent.componentId}
        />
      );
    }

    if (containerChildComponent.resourceType === NAVIGATION_LINK) {
      return (
        <NavigationLinks {...containerChildComponent} key={containerChildComponent.componentId} />
      );
    }

    if (
      containerChildComponent.resourceType === CONTAINER ||
      containerChildComponent.resourceType === RESPONSIVE_GRID
    ) {
      return containerComponent(containerChildComponent);
    }

    if (containerChildComponent.resourceType === IMAGE) {
      return (
        <img
          src={containerChildComponent.dynamicImages?.highQuality95}
          alt=""
          height={containerChildComponent.assetMetadataHeight}
          width={containerChildComponent.assetMetadataWidth}
          loading="lazy"
          key={containerChildComponent.componentId}
        />
      );
    }

    return null;
  });

  // This is to remove unnecessary wrapping components usually used for grid classes
  if (!component.linkPath && !component.elementId) {
    return mappedComponents;
  }

  return (
    <Component key={component.uniqueId} id={component.elementId} href={component.linkPath}>
      {mappedComponents}
    </Component>
  );
};

export const SeasonalMenuExperienceFragment = ({
  content,
}: SeasonalMenuExperienceFragmentProps) => {
  if (!content.length) return null;

  const [wrapperComponent] = content;

  if (
    wrapperComponent.resourceType !== EXPERIENCE_FRAGMENT_REFERENCE ||
    !wrapperComponent?.childComponents?.length
  ) {
    return null;
  }

  const [gridComponents] = wrapperComponent.childComponents;

  return (
    <div id="cms-seasonalMenu-content">
      {gridComponents?.childComponents?.map((component: any) => containerComponent(component))}
    </div>
  );
};
