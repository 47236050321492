import { useEffect } from 'react';

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      const { target } = event;
      if (!ref.current || ref.current.contains(target)) {
        return;
      }

      handler(event);
    };

    // add listeners in capture mode to prevent the target being removed from DOM
    // tree before we have had a chance to check whether the ref contains it

    window.document.addEventListener('click', listener, true);
    window.document.addEventListener('touchstart', listener, true);

    return () => {
      window.document.removeEventListener('click', listener, true);
      window.document.removeEventListener('touchstart', listener, true);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
