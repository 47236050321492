import { HttpResponse, ServiceClient } from '@johnlewispartnership/wtr-website-api-client';
import config from 'config';
import { logApiError } from 'logger';
import { CustomerOrderState, ShoppingContext } from 'services/shoppingContext/types';
import getApiBaseUrl from 'utils/getApiBaseUrl';

export const defaultShoppingContext = {
  customerId: '',
  customerOrderId: '',
  customerOrderState: CustomerOrderState.PENDING,
  defaultBranchId: '',
};
class ShoppingContextServiceClient extends ServiceClient {
  async getShoppingContext({ authHeader }: { authHeader: string }): Promise<ShoppingContext> {
    const { data, error, duration, status } = await this.httpClient
      .get<ShoppingContext>('/v1/shopping-context', {
        headers: {
          Authorization: authHeader,
        },
      })
      .catch(e => ({ error: e } as HttpResponse<ShoppingContext>));

    if (!data || error) {
      logApiError({
        payload: {
          service: 'shoppingContext',
          duration,
          httpStatus: String(status || ''),
          httpStatusCode: status,
          ...(error && { errorDetails: error }),
          url: `${getApiBaseUrl(config.services.shoppingContext.path)}/v1/shopping-context`,
          message: `Error getting shopping context: ${
            error?.message || 'Shopping context missing from response'
          }`,
        },
      });
      return defaultShoppingContext;
    }

    return data;
  }
}

const shoppingContextServiceClient = new ShoppingContextServiceClient(
  getApiBaseUrl(config.services.shoppingContext.path),
);

export default shoppingContextServiceClient;
