import React, { useMemo, Suspense } from 'react';
import { IconProps } from '@johnlewispartnership/wtr-ingredients/dist/foundations/icons/svg-icon';
import { SimpleErrorBoundary } from 'components/SimpleErrorBoundary/SimpleErrorBoundary';
import { isWindowAvailable } from 'utils/browser';

// React.lazy expects a default export, so we need re-export the named export as default
// Based off solution provided: https://github.com/facebook/react/issues/14603#issuecomment-726551598
function lazyImport<
  T extends React.ComponentType<IconProps>,
  I extends { [K2 in K]: T },
  K extends keyof I,
>(factory: () => Promise<I>, name: K) {
  return React.lazy(() => factory().then(module => ({ default: module[name] as T })));
}

export const LazyIcon = ({ name, className, size }: IconProps) => {
  const Icon = useMemo(() => {
    if (isWindowAvailable && name) {
      const LazyLoadedIcon = lazyImport(
        () =>
          import(`@johnlewispartnership/wtr-ingredients/dist/foundations/icons/icons/${name}.js`),
        name,
      );

      return () => (
        <SimpleErrorBoundary section={`LazyIcon ${name}`}>
          <Suspense fallback={null}>
            <LazyLoadedIcon {...{ className, size }} />
          </Suspense>
        </SimpleErrorBoundary>
      );
    }
    return () => null;
  }, [name, className, size]);

  return <Icon />;
};
