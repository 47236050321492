import React from 'react';
import { func, node, string } from 'prop-types';
import { LinkAsButton } from '@johnlewispartnership/wtr-ingredients/ingredients/LinkAsButton';
import { urls } from 'constants/urls';

export const GoToTrolley = ({
  children,
  className,
  id,
  onClick,
  originComponent,
  title,
  testIdSuffix,
}) => {
  const anchorProps = {
    'aria-label': title,
    className,
    id,
    onClick,
    title,
  };

  return (
    <LinkAsButton
      {...anchorProps}
      data-testid={`header-trolley-link${testIdSuffix ? `-${testIdSuffix}` : ''}`}
      data-actiontype="redirect"
      data-origincomponent={originComponent}
      data-shortdescription="Goes to trolley details"
      theme="secondary"
      href={urls.trolleyPage}
    >
      {children}
    </LinkAsButton>
  );
};

GoToTrolley.propTypes = {
  children: node.isRequired,
  className: string,
  id: string,
  originComponent: string,
  onClick: func,
  title: string,
  testIdSuffix: string,
};

GoToTrolley.defaultProps = {
  className: '',
  id: null,
  originComponent: null,
  onClick: () => {},
  title: 'Go to your shopping trolley',
  testIdSuffix: '',
};
