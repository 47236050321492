import update from 'immutability-helper';

import { MEGA_MENU } from 'constants/categoryIds';

import {
  ADD_MEGAMENU_HISTORY,
  CLEAR_MEGAMENU_HISTORY,
  CLOSE_MEGAMENU,
  OPEN_MEGAMENU,
  SET_MEGAMENU_ACTIVE_ITEM,
  SET_MEGAMENU_HISTORY,
  SET_MEGAMENU_ROOT,
} from 'redux/modules/mega-menu/actions/types';

const initialState = {
  activeItem: MEGA_MENU,
  history: [],
  menuRoot: MEGA_MENU,
  open: false,
};

export default function (state = initialState, action = {}) {
  const { type } = action;

  switch (type) {
    case ADD_MEGAMENU_HISTORY: {
      const { level } = action;

      return {
        ...state,
        history: update(state.history, { $push: [level] }),
      };
    }
    case CLEAR_MEGAMENU_HISTORY: {
      return {
        ...state,
        history: [],
      };
    }
    case CLOSE_MEGAMENU: {
      return {
        ...state,
        open: false,
      };
    }
    case OPEN_MEGAMENU: {
      return {
        ...state,
        open: true,
      };
    }
    case SET_MEGAMENU_ACTIVE_ITEM: {
      const { activeItem } = action;

      return {
        ...state,
        activeItem,
      };
    }
    case SET_MEGAMENU_HISTORY: {
      const { history } = action;

      return {
        ...state,
        history: update(state.history, { $set: history }),
      };
    }
    case SET_MEGAMENU_ROOT: {
      const { menuRoot } = action;

      return {
        ...state,
        menuRoot,
      };
    }
    default:
      return state;
  }
}
