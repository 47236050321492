/* eslint-disable no-underscore-dangle */
import { configureStore } from '@reduxjs/toolkit';
import megaMenuReducer from 'redux/modules/mega-menu/reducer';
import taxonomyReducer from 'redux/modules/taxonomy/reducer';
import pageReducer from 'redux/modules/page/reducer';
import autoSuggestReducer from 'redux/modules/auto-suggest/reducer';
import { isWindowAvailable } from 'utils/browser';
import searchReducer from 'redux/modules/search/reducer';

export const createStore = () =>
  configureStore({
    reducer: {
      megaMenu: megaMenuReducer,
      taxonomy: taxonomyReducer,
      page: pageReducer,
      search: searchReducer,
      autoSuggest: autoSuggestReducer,
    },
    devTools: true,
    preloadedState: isWindowAvailable ? window?.__WTR_COMMON__?.header?.preloadedState : {},
  });
