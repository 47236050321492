import { TokenServiceClient } from '@johnlewispartnership/wtr-website-authentication/dist/service';
import config from 'config';
import { logger } from 'logger';
import getApiBaseUrl from 'utils/getApiBaseUrl';

export default new TokenServiceClient(getApiBaseUrl(config.services.tokenClient.path), {
  error: e => {
    // TODO: this need more work to include things like customerID, ip, the full URL etc...
    logger.error({
      payload: {
        service: 'token',
        logType: 'authentication',
        url: `${getApiBaseUrl(config.services.tokenClient.path)}/...`,
        message: e.message,
        ...(e.originalError?.message && {
          errorDetails: {
            message: e.originalError?.message,
          },
        }),
      },
    });
  },
});
