import queryString from 'query-string';

export const getSearchValueFromSearchQuery = search => {
  const query = queryString.parse(search);
  const searchTermParam = [query.searchTerm][0];
  return searchTermParam ? searchTermParam.replace(/[^\w\s&'.,+-]/gi, '').trim() : '';
};

export const getSearchQueryFromApiRequest = apiRequest => {
  const apiRequestFilterTags = apiRequest.filterTags || [];
  const customTagsArray = [];
  const popularTagsArray = [];
  const filtersArray = [];
  let category = '';
  let searchTerm = '';
  let customTags = '';
  let popularTags = '';
  let filterTags = '';
  let sortBy = '';

  // eslint-disable-next-line
  for (const tag of apiRequest.searchTags || []) {
    if (tag.group === 'CUSTOM') {
      customTagsArray.push(encodeURIComponent(tag.text));
    } else {
      popularTagsArray.push(encodeURIComponent(tag.text));
    }
  }

  // eslint-disable-next-line
  for (const filter of apiRequestFilterTags) {
    filtersArray.push(`${encodeURIComponent(filter.id)}:${encodeURIComponent(filter.value)}`);
  }
  searchTerm = apiRequest.searchTerm
    ? `&searchTerm=${encodeURIComponent(apiRequest.searchTerm)}`
    : '';
  sortBy = apiRequest.sortBy ? `&sortBy=${encodeURIComponent(apiRequest.sortBy)}` : '';
  customTags = customTagsArray.length > 0 ? `&ct=${customTagsArray.join()}` : '';
  popularTags = popularTagsArray.length > 0 ? `&pt=${popularTagsArray.join()}` : '';
  filterTags = apiRequestFilterTags.length > 0 ? `&flt=${filtersArray.join(';')}` : '';
  category = apiRequest.category ? `&cat=${apiRequest.category}` : '';

  return searchTerm + sortBy + customTags + popularTags + filterTags + category;
};
