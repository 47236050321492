import React, { useCallback, useState, useMemo } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { bool, object, string } from 'prop-types';
import { format } from 'date-fns/format';
import classNames from 'classnames';
import { useAnalytics } from '@johnlewispartnership/wtr-website-analytics';
import Alert from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Alert';
import {
  CalendarBooked as CalendarBookedIcon,
  Calendar as CalendarIcon,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { LinkAsButton } from '@johnlewispartnership/wtr-ingredients/ingredients/LinkAsButton';
import Spinner from '@johnlewispartnership/wtr-ingredients/dist/Spinner';
import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import { urls } from 'constants/urls';
import { formatSlotTime } from 'utils/format-slot-time';
import { KEY_ENTER } from 'constants/keys';
import { isCollectionSlotType } from 'utils/slots';
import { useTrolley } from 'contexts/Trolley';
import { SlotType } from 'services/slotOrchestration/types';
import styles from './SlotButton.module.scss';
import { formatSlotButtonText } from './utils/format-slot-button';

export const SlotButton = ({
  isSlotBooked,
  slotDetails,
  slotAddress,
  slotLoading,
  testIdSuffix,
  isMobile,
  slotError,
}) => {
  const { trackEvent } = useAnalytics();
  const { state: trolleyState } = useTrolley();

  const [hidden, setHidden] = useState(true);

  const handleChangeSlot = useCallback(() => {
    setHidden(true);

    trackEvent({
      event: 'change_slot_button_click',
      eventAction: 'ClickChangeSlot',
      eventCategory: 'On-Page Interactions',
      eventLabel: `ViewSlotBooked`,
      eventValue: undefined,
    });
  }, [trackEvent]);

  const handleClickOutside = useCallback(() => {
    if (!hidden) {
      setHidden(true);
    }
  }, [hidden]);

  const toggleVisibility = useCallback(() => {
    if (hidden) {
      trackEvent({
        event: 'slot_details_button_click',
        eventAction: 'ClickSlotDetails',
        eventCategory: 'On-Page Interactions',
        eventLabel: `Header`,
        eventValue: undefined,
      });
    }

    setHidden(!hidden);
  }, [hidden, trackEvent]);

  const handleKeyDown = useCallback(
    event => {
      const { keyCode } = event;

      if (keyCode === KEY_ENTER) {
        handleChangeSlot();
      }
    },
    [handleChangeSlot],
  );

  const handleLinkClick = () => {
    trackEvent({
      event: 'click_book_slot',
    });
  };

  const isCollection = isCollectionSlotType(slotDetails?.slotType);

  const startIcon =
    isSlotBooked && slotDetails?.startDateTime ? <CalendarBookedIcon /> : <CalendarIcon />;
  const spinnerTheme = isMobile ? 'dark' : 'light';

  const slotButtonText = slotError
    ? 'Slot'
    : formatSlotButtonText({
        isSlotBooked,
        shortTime: isMobile,
        slotEndDateTime: slotDetails?.endDateTime,
        slotStartDateTime: slotDetails?.startDateTime,
      });

  const slotButtonLabel = isSlotBooked || slotError ? 'Slot' : 'Book a slot';
  const hasSlotInformation = isSlotBooked && slotDetails?.startDateTime;

  const longDate = useMemo(
    () =>
      hasSlotInformation
        ? `${format(slotDetails?.startDateTime, 'eeee')} ${format(
            slotDetails?.startDateTime,
            'dd',
          )} ${format(slotDetails?.startDateTime, 'MMMM')}`
        : '',
    [hasSlotInformation, slotDetails?.startDateTime],
  );

  const longTime = useMemo(
    () =>
      hasSlotInformation
        ? `${formatSlotTime(slotDetails?.startDateTime)}-${formatSlotTime(
            slotDetails?.endDateTime,
          )}`
        : '',
    [hasSlotInformation, slotDetails?.endDateTime, slotDetails?.startDateTime],
  );

  const slotButtonLabelId = `slot-button-label-${testIdSuffix}`;
  const blurButton = () => setHidden(true);
  const focusButton = () => setHidden(false);
  const changeSlotUrl = useMemo(() => {
    switch (slotDetails?.slotType) {
      case SlotType.DELIVERY:
        return urls.bookDeliverySlot;
      case SlotType.ENTERTAINING_COLLECTION:
        return urls.bookEntertainingCollectionSlot;
      case SlotType.GROCERY_COLLECTION:
        return urls.bookGroceriesAndEntertainingCollectionSlot;
      default:
        return urls.serviceSelection;
    }
  }, [slotDetails?.slotType]);

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <div className={styles.wrapper} data-testid={`slot-button-${testIdSuffix}`}>
        <div className={classNames({ [styles.textOnly]: isMobile })}>
          {isSlotBooked ? (
            <Button
              aria-hidden
              data-testid={`slot-details-button-${testIdSuffix}`}
              startIcon={!isMobile ? <CalendarBookedIcon /> : undefined}
              label={slotButtonText}
              onClick={toggleVisibility}
              tabIndex="-1"
              theme={!isMobile ? 'primary' : 'primaryWhite'}
              width="full"
            />
          ) : (
            <LinkAsButton
              aria-label={
                slotLoading ? 'Loading slots. Click here to go to slots page.' : slotButtonLabel
              }
              href={urls.serviceSelection}
              data-testid={`book-slot-button-${testIdSuffix}`}
              startIcon={!isMobile ? startIcon : undefined}
              onClick={handleLinkClick}
              theme={!isMobile ? 'primary' : 'secondary'}
              type="button"
              width="full"
            >
              {slotLoading ? (
                <Spinner isActive size="small" theme={spinnerTheme} />
              ) : (
                slotButtonText
              )}
            </LinkAsButton>
          )}
        </div>
        {isSlotBooked && (
          <div
            className={classNames(styles.details, { [styles.srOnly]: hidden })}
            data-testid={`slot-details-${testIdSuffix}`}
          >
            <div className={styles.header}>
              <header aria-hidden="true">
                Your {isCollection ? 'collection' : 'delivery'} slot
              </header>
            </div>
            <div className={styles.slotDateTime}>
              <span className={styles.srOnly}>Booked for:</span>
              <span>{longDate}</span>
              <span className={styles.slotTime}>{longTime}</span>
            </div>
            <div
              className={styles.deliveryDetails}
              data-cs-mask
              data-testid={`slot-address-details-${testIdSuffix}`}
            >
              <span className={styles.title}>
                {isCollection ? 'Collect from:' : 'Delivery to:'}
              </span>
              {isCollection && slotAddress.name && (
                <span className={styles.address}>{slotAddress.name}</span>
              )}
              <span className={styles.address}>{slotAddress.line1}</span>
              {slotAddress.line2 && <span className={styles.address}>{slotAddress.line2}</span>}
              <span className={styles.address}>{slotAddress.town}</span>
              {slotAddress.region && <span className={styles.address}>{slotAddress.region}</span>}
              <span className={styles.address}>{slotAddress.postalCode}</span>
            </div>
            {trolleyState.trolley?.amendingOrder && !trolleyState.slotChangeable ? (
              <div className={styles.alert}>
                <Alert
                  data-testid="slot-details-alert"
                  message="Your order contains items currently being prepared. We are unable to change the delivery/collection time or cancel the order."
                  small
                  type="warning"
                />
              </div>
            ) : (
              <div className={styles.button}>
                <span className={styles.srOnly} htmlFor="changeSlotButton" id={slotButtonLabelId}>
                  {isCollection ? 'Collection' : 'Delivery'} slot booked for {longDate} {longTime}.{' '}
                  {isCollection ? 'Collect from' : 'Delivery to'} {slotAddress.line1},
                  {slotAddress.line2 ? ` ${slotAddress.line2},` : ''} {slotAddress.town},
                  {slotAddress.region ? ` ${slotAddress.region},` : ''} {slotAddress.postalCode}.
                  Change slot.
                </span>
                <LinkAsButton
                  aria-describedby={slotButtonLabelId}
                  aria-label="Change slot"
                  data-testid={`change-slot-button-${testIdSuffix}`}
                  component="a"
                  label="Change slot"
                  onBlur={blurButton}
                  onClick={handleChangeSlot}
                  onFocus={focusButton}
                  onKeyDown={handleKeyDown}
                  theme="primary"
                  href={changeSlotUrl}
                  type="button"
                  width="full"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

SlotButton.propTypes = {
  isSlotBooked: bool,
  slotDetails: object,
  slotAddress: object,
  slotLoading: bool,
  testIdSuffix: string,
  isMobile: bool,
  slotError: bool,
};

SlotButton.defaultProps = {
  isSlotBooked: false,
  slotDetails: {},
  slotAddress: {},
  slotLoading: false,
  testIdSuffix: 'desktop',
  isMobile: false,
  slotError: false,
};
