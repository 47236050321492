import { useContext } from 'react';
import TrolleyContext from './TrolleyContext';

const useTrolley = () => {
  const trolleyContext = useContext(TrolleyContext);

  if (trolleyContext === undefined) {
    throw new Error('useTrolley must be used within a TrolleyProvider');
  }

  return trolleyContext;
};

export default useTrolley;
