import React from 'react';
import PropTypes from 'prop-types';
import styles from './SiteHeaderOverlay.scss';

const SiteHeaderOverlay = ({ isActive }) => (
  <div
    className={styles[isActive ? 'overlayActive' : 'overlay']}
    data-testid="site-header-overlay"
  />
);

SiteHeaderOverlay.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default SiteHeaderOverlay;
