import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import {
  TrolleyActive,
  TrolleyInactive,
} from '@johnlewispartnership/wtr-ingredients/dist/foundations/icons';
import { BREAKPOINTS } from 'constants/grid';
import styles from './TrolleySize.module.scss';

export const TrolleySize = ({ dataTestId, highlightValue, size }) => {
  const isBreakPointSM = useMediaQuery({ maxWidth: BREAKPOINTS.sm });
  const itemQty = size > 99 ? '99+' : size;

  const TrolleyIcon = size ? TrolleyActive : TrolleyInactive;
  const TrolleyIconSize = size ? 'small' : 'medium';

  return (
    <span
      data-testid="trolley-size-container"
      className={classNames(styles.trolleySize, {
        [styles.highlight]: highlightValue,
      })}
    >
      {isBreakPointSM ? null : (
        <div className={styles.icon}>
          <TrolleyIcon size={TrolleyIconSize} />
        </div>
      )}

      <span className={styles.value} data-testid={dataTestId}>
        {itemQty || null}
      </span>
    </span>
  );
};

TrolleySize.propTypes = {
  dataTestId: PropTypes.string,
  highlightValue: PropTypes.bool,
  size: PropTypes.number,
};

TrolleySize.defaultProps = {
  dataTestId: '',
  highlightValue: true,
  size: 50,
};
