import { useContext } from 'react';
import { CookieConsentContext } from './CookieConsentContext';

const useCookieConsent = () => {
  const cookieConsent = useContext(CookieConsentContext);

  if (cookieConsent === undefined) {
    throw new Error('useCookieConsent must be used within a CookieConsentProvider');
  }

  return cookieConsent;
};

export default useCookieConsent;
