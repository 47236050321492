import categoryIds from 'constants/categoryIds';
import { MEGA_MENU_MAX_LENGTH } from 'redux/modules/page/constants';
import { SET_MEGA_MENU_START } from 'redux/modules/page/actions/types';

const GROCERIES_ROOT = 'Groceries';

export const setMegaMenuStart = menuRoot => (dispatch, getState) => {
  if (getState().taxonomy.rootCategories) {
    let id = categoryIds.SHOP;
    let maxLength = MEGA_MENU_MAX_LENGTH;
    let name = null;
    let { path } = getState().page.megaMenu;
    let urlName = null;

    if (menuRoot === GROCERIES_ROOT) {
      id = getState().taxonomy.rootCategories.find(category => category.name === GROCERIES_ROOT).id;
      name = GROCERIES_ROOT;
      urlName = GROCERIES_ROOT;
      path = `${path}/${GROCERIES_ROOT}`;
      maxLength = 3;
    }

    dispatch({
      id,
      maxLength,
      menuRoot,
      name,
      path,
      type: SET_MEGA_MENU_START,
      urlName,
    });
  }
};
