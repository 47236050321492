import React from 'react';
import { Logo as WaitroseLogo } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Logo';

import styles from './Logo.module.scss';

export interface LogoProps {
  small?: boolean;
}

const Logo = ({ small = false }: LogoProps) => {
  const defaultLogo = small ? (
    <WaitroseLogo optimised className={styles.logoSmall} clearSpace={false} />
  ) : (
    <WaitroseLogo className={styles.logoRegular} clearSpace={false} />
  );

  return (
    <>
      {defaultLogo}
      <span className={styles.srOnly}>Visit Waitrose.com</span>
    </>
  );
};

Logo.displayName = 'Logo';

export default Logo;
