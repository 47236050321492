import Cookies from 'universal-cookie';
import {
  COOKIE_ADVERTISING,
  COOKIE_ANALYTICS,
  COOKIE_CONSENT,
  COOKIE_DOMAIN,
  COOKIE_FUNCTIONAL,
  COOKIE_PATH,
} from 'constants/cookies';

export const getFunctionalCookieState = () =>
  new Cookies(undefined, { domain: COOKIE_DOMAIN, path: COOKIE_PATH }).get(COOKIE_FUNCTIONAL, {
    doNotParse: true,
  }) === '1';

export const getCookiePreferences = (cookieHeader?: string): CookieConsentContextType => {
  const cookies = new Cookies(cookieHeader, {
    domain: COOKIE_DOMAIN,
    path: COOKIE_PATH,
  });

  return {
    functionalCookiesAccepted: cookies.get(COOKIE_FUNCTIONAL, { doNotParse: true }) === '1',
    analyticsCookiesAccepted: cookies.get(COOKIE_ANALYTICS, { doNotParse: true }) === '1',
    advertisingCookiesAccepted: cookies.get(COOKIE_ADVERTISING, { doNotParse: true }) === '1',
    hasConsentCookie: cookies.get(COOKIE_CONSENT, { doNotParse: true }) === '1',
  };
};

export const setCookie = (name: string, value: string) => {
  const cookies = new Cookies(undefined, { domain: COOKIE_DOMAIN, path: COOKIE_PATH });

  cookies.set(name, value);
};

export const deleteCookie = (name: string) => {
  const cookies = new Cookies(undefined, { domain: COOKIE_DOMAIN, path: COOKIE_PATH });
  cookies.remove(name);
};
