import React, { useRef, useMemo } from 'react';
import { bool, func, string } from 'prop-types';
import { useAuthentication } from '@johnlewispartnership/wtr-website-authentication/dist/context';
import { chainHandlers } from 'utils/chain-handlers';
import { useMenuFocus } from 'components/MegaMenu/MenuFocusProvider';

// TODO: Merge this component with ./Button/index.js if possible
const SignOut = ({ className, loggedIn, onBlur, onClick, onFocus, onLogoutUser }) => {
  const { state: authState } = useAuthentication();
  const visible = loggedIn && !authState.isSignedOnAsCustomer;
  const ref = useRef();
  const { handleClick, handleKeyDown, tabIndex } = useMenuFocus(ref, {
    skip: !visible,
  });

  const chainedHandleClick = useMemo(
    () => chainHandlers(handleClick, onClick, onLogoutUser),
    [handleClick, onClick, onLogoutUser],
  );

  return visible ? (
    <button
      className={className}
      data-test="signOut"
      onBlur={onBlur}
      onClick={chainedHandleClick}
      onFocus={onFocus}
      onKeyDown={handleKeyDown}
      ref={ref}
      tabIndex={tabIndex}
      type="button"
    >
      Sign out
    </button>
  ) : null;
};

SignOut.propTypes = {
  className: string,
  loggedIn: bool.isRequired,
  onBlur: func,
  onClick: func,
  onFocus: func,
  onLogoutUser: func.isRequired,
};

SignOut.defaultProps = {
  className: null,
  onBlur: null,
  onClick: null,
  onFocus: null,
};

SignOut.displayName = 'SignOut';

export { SignOut };
