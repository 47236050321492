export type SlotReservations = SlotReservation[];
export type SlotErrors = SlotError[];
export type GetSlotReservationsResponseData = SlotReservations | SlotErrors;

export interface SlotError {
  field: string;
  message: string;
}

export interface SlotReservation {
  id: string;
  slotGridType: string;
  branchId: number;
  slotType: SlotType;
  customerOrderId: number;
  addressId: string;
  postcode: string;
  startDateTime: string;
  endDateTime: string;
  expiryDateTime: string;
  orderCutoffDateTime: string;
  amendOrderCutoffDateTime: string;
  shopByDateTime: any;
  status: string;
  deliveryCharge: DeliveryCharge;
}

export enum SlotType {
  DELIVERY = 'DELIVERY',
  GROCERY_COLLECTION = 'GROCERY_COLLECTION',
  ENTERTAINING_COLLECTION = 'ENTERTAINING_COLLECTION',
  DELIVERY_SERVICE = 'DELIVERY_SERVICE',
  UNDEFINED = 'UNDEFINED',
}

export interface DeliveryCharge {
  currencyCode: string;
  amount: number;
}
export type GetSlotReservationsError = {
  message: string;
};

export type GetSlotReservationsResult = {
  reservations: SlotReservations;
  error?: GetSlotReservationsError;
};
