import { connect } from 'react-redux';
import {
  getAutoSuggestActive,
  getAutoSuggestSearchTerm,
  getAutoSuggestSuggestions,
} from 'redux/modules/auto-suggest/selectors';

import { AutoComplete } from './AutoComplete';

export default connect(state => ({
  active: getAutoSuggestActive(state),
  searchTerm: getAutoSuggestSearchTerm(state),
  suggestions: getAutoSuggestSuggestions(state),
}))(AutoComplete);
