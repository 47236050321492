// import { LOCATION_CHANGE } from 'connected-react-router';
import update from 'immutability-helper';

import {
  CLIENT_PAGE_LOADED,
  CLIENT_PAGE_LOADING,
  PAGE_CLEAR_COOKIES,
  PAGE_CLEAR_PRELOADED,
  PAGE_CLEAR_SERVER_GTM_EVENTS,
  PAGE_CLEAR_SERVER_MONETATE_EVENTS,
  PAGE_SET_PRELOADED,
  PAGE_SET_REQUEST_COOKIES,
  PAGE_SET_RESPONSE_COOKIES,
  PAGE_SET_SERVER_GTM_EVENTS,
  PAGE_SET_SERVER_MONETATE_EVENTS,
  SET_MEGA_MENU,
  SET_MEGA_MENU_ACTIVE_CATEGORY,
  SET_MEGA_MENU_ROOT_CATEGORY,
  SET_MEGA_MENU_ACTIVE_LEVEL,
  SET_MEGA_MENU_START,
  SET_MEGA_MENU_STATE,
  SET_CLEAR_VALUE,
  SET_MOBILE_OVERLAY,
  SET_PAGE_DEPTH,
  SET_SIDEBAR_STATE,
  TOGGLE_PIN_BAR_OPEN,
} from 'redux/modules/page/actions/types';

import { initialState } from './initialState';

export default function page(state = initialState, action = {}) {
  switch (action.type) {
    case CLIENT_PAGE_LOADED:
      return {
        ...state,
        isClientPageLoaded: true,
      };
    case CLIENT_PAGE_LOADING:
      return {
        ...state,
        isClientPageLoaded: false,
      };
    case PAGE_CLEAR_COOKIES:
      return {
        ...state,
        requestCookies: initialState.requestCookies,
        responseCookies: initialState.responseCookies,
      };
    case PAGE_CLEAR_PRELOADED:
      return {
        ...state,
        preloadedPath: '',
      };
    case PAGE_CLEAR_SERVER_GTM_EVENTS:
      return {
        ...state,
        serverGtmEvents: initialState.serverGtmEvents,
      };
    case PAGE_CLEAR_SERVER_MONETATE_EVENTS:
      return {
        ...state,
        serverMonetateEvents: initialState.serverMonetateEvents,
      };
    // case LOCATION_CHANGE:
    //   return action.payload.isFirstRendering
    //     ? state
    //     : update(state, {
    //         navigation: {
    //           history: {
    //             $unshift: [
    //               {
    //                 action: action.payload.action,
    //                 pathname: action.payload.location.pathname,
    //               },
    //             ],
    //           },
    //         },
    //       });
    case PAGE_SET_PRELOADED:
      return {
        ...state,
        preloadedPath: action.payload,
      };
    case PAGE_SET_REQUEST_COOKIES:
      return {
        ...state,
        requestCookies: action.payload,
      };
    case PAGE_SET_RESPONSE_COOKIES:
      return {
        ...state,
        responseCookies: [...state.responseCookies, ...action.payload.filter(item => item)],
      };
    case PAGE_SET_SERVER_GTM_EVENTS:
      return {
        ...state,
        serverGtmEvents: [...action.payload],
      };
    case PAGE_SET_SERVER_MONETATE_EVENTS:
      return {
        ...state,
        serverMonetateEvents: [...action.payload],
      };
    case SET_SIDEBAR_STATE:
      return {
        ...state,
        isSideBarOpen: action.isSideBarOpen,
      };
    case SET_MOBILE_OVERLAY:
      return {
        ...state,
        isMobileOverlayOpen: action.isMobileOverlayOpen,
      };
    case SET_CLEAR_VALUE:
      return {
        ...state,
        isValueClear: action.isValueClear,
      };
    case TOGGLE_PIN_BAR_OPEN:
      return {
        ...state,
        isPinBarOpen: !state.isPinBarOpen,
      };
    case SET_MEGA_MENU:
      return {
        ...state,
        megaMenu: update(state.megaMenu, {
          menus: {
            $splice: [
              [
                action.level,
                state.megaMenu.menus.length,
                {
                  id: action.id,
                  name: action.name,
                  urlName: action.urlName,
                  path: action.path,
                },
              ],
            ],
          },
        }),
      };
    case SET_MEGA_MENU_ACTIVE_CATEGORY: {
      const { categoryId } = action;

      return {
        ...state,
        megaMenu: update(state.megaMenu, {
          activeItem: { $set: categoryId },
        }),
      };
    }
    case SET_MEGA_MENU_ROOT_CATEGORY: {
      const { categoryId } = action;

      return {
        ...state,
        megaMenu: update(state.megaMenu, {
          rootCategory: { $set: categoryId },
        }),
      };
    }
    case SET_MEGA_MENU_ACTIVE_LEVEL: {
      const { level } = action;

      return {
        ...state,
        megaMenu: update(state.megaMenu, {
          activeLevel: { $set: level },
        }),
      };
    }
    case SET_MEGA_MENU_STATE:
      return {
        ...state,
        megaMenu: {
          ...state.megaMenu,
          isOpen: action.isMegaMenuOpen,
        },
      };
    case SET_MEGA_MENU_START:
      return {
        ...state,
        megaMenu: update(state.megaMenu, {
          menus: {
            $set: [
              {
                id: action.id,
                name: action.name,
                path: action.path,
                urlName: action.urlName,
              },
            ],
          },
          menuRoot: { $set: action.menuRoot },
          activeLevel: { $set: 0 },
          maxLength: { $set: action.maxLength },
        }),
      };
    case SET_PAGE_DEPTH:
      return {
        ...state,
        currentPageDepth: action.pageType + (action.pageDepth ? action.pageDepth - 1 : 0),
      };
    default:
      return state; // no match found, just return the state as is
  }
}
