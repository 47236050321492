import React from 'react';
import { bool, number, string } from 'prop-types';
import { formatAsPositivePoundsOrPence } from 'utils/currency';
import GoToTrolley from 'components/GoToTrolley';
import TrolleyButtonContents from './TrolleyButtonContents';
import styles from './ShoppingSummary.module.scss';

export const ShoppingSummary = ({
  loadingStatus,
  totalEstimatedCost,
  totalNumberOfItems,
  hasTrolleyError,
  testIdSuffix,
}) => {
  const estimatedCost = formatAsPositivePoundsOrPence(totalEstimatedCost);
  const description = `trolley, ${totalNumberOfItems} item${
    totalNumberOfItems !== 1 ? 's' : ''
  } ${estimatedCost} total, view trolley.`;

  return (
    <div className={styles.shoppingSummary}>
      <section
        className={styles.trolleyActions}
        data-actiontype="redirect"
        data-customdata="category:Header"
        data-origincomponent="ShoppingSummary"
        data-shortdescription={hasTrolleyError ? null : description}
      >
        {/* TODO Have GoToTrolley & TrolleyButtonContents components as JSX here if not reused */}
        <GoToTrolley
          className={styles.trolleyLink}
          label={estimatedCost}
          originComponent="site-header accessible trolley link"
          testIdSuffix={testIdSuffix}
          title={hasTrolleyError ? null : description}
        >
          <TrolleyButtonContents
            hasTrolleyError={hasTrolleyError}
            estimatedCost={estimatedCost}
            loadingStatus={loadingStatus}
            totalNumberOfItems={totalNumberOfItems}
            testIdSuffix={testIdSuffix}
          />
        </GoToTrolley>
      </section>
    </div>
  );
};

ShoppingSummary.propTypes = {
  loadingStatus: bool,
  totalEstimatedCost: number,
  totalNumberOfItems: number,
  hasTrolleyError: bool,
  testIdSuffix: string,
};

ShoppingSummary.defaultProps = {
  loadingStatus: false,
  totalEstimatedCost: 0,
  totalNumberOfItems: 0,
  hasTrolleyError: false,
  testIdSuffix: '',
};
