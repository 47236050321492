import { MEGA_MENU_MAX_LENGTH, MEGA_MENU_PATH } from 'redux/modules/page/constants';

export const initialState = {
  currentPageDepth: undefined,
  navigation: {
    history: [] as any[],
  },
  isClientPageLoaded: false,
  isMobileOverlayOpen: false,
  isValueClear: false,
  isSideBarOpen: false,
  isPinBarOpen: false,
  megaMenu: {
    isOpen: false,
    activeItem: undefined,
    activeLevel: -1,
    path: MEGA_MENU_PATH,
    menus: [] as any[],
    menuRoot: 'Shop',
    maxLength: MEGA_MENU_MAX_LENGTH,
    rootCategory: undefined,
  },
  preloadedPath: '',
  requestCookies: '',
  responseCookies: [] as any[],
  serverGtmEvents: [] as any[],
  serverMonetateEvents: [] as any[],
};
