import React from 'react';
import PropTypes from 'prop-types';
import { rootPath } from 'utils/root-path';
import styles from './DropdownNavHidden.scss';

export const DropdownNavHidden = ({ taxonomy }) => (
  <div className={styles.dropdownG1G2Hidden}>
    {taxonomy.map(item => (
      <a key={item.id} id={item.id} href={rootPath(item.path.toLowerCase())}>
        {item.name}
      </a>
    ))}
  </div>
);

DropdownNavHidden.propTypes = {
  taxonomy: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
      id: PropTypes.string,
      level: PropTypes.number,
      parent: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

DropdownNavHidden.defaultProps = {
  taxonomy: [],
};
