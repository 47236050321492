import { connect } from 'react-redux';
import { thirdColumnMenu } from 'constants/cms-menus';
import {
  getMegaMenuHistory,
  getMegaMenuOpen,
  getNavigationMenus,
  getPreviousActiveTitle,
} from 'redux/modules/mega-menu/selectors';

import closeMegaMenuSubcategory from 'redux/modules/mega-menu/actions/close-mega-menu-subcategory';
import closeSlideOutNav from 'redux/modules/mega-menu/actions/close-slide-out-nav';
import openSlideOutNav from 'redux/modules/mega-menu/actions/open-slide-out-nav';
import openMegaMenuSubcategory from 'redux/modules/mega-menu/actions/open-mega-menu-subcategory';
import { navigateFromMegaMenu } from 'redux/modules/page/actions/navigate-from-mega-menu';

import { SlideOutNav } from './SlideOutNav';

export default connect(
  (state, ownProps) => ({
    level: getMegaMenuHistory(state).length,
    // contants/cms-menus
    menus: getNavigationMenus(state, ownProps.isLoggedIn),
    open: getMegaMenuOpen(state),
    // Previus section title used to show on to top of slideOutNav
    previousTitle: getPreviousActiveTitle(state),
    // contants/cms-menus
    specialistShopLinks: thirdColumnMenu,
  }),
  (dispatch, ownProps) => ({
    closeMegaMenu: () => dispatch(closeSlideOutNav()),
    closeSubcategory: () => dispatch(closeMegaMenuSubcategory()),
    navigateFromMegaMenu: (level, name, id) => dispatch(navigateFromMegaMenu(level, name, id)),
    openMegaMenu: () => dispatch(openSlideOutNav()),
    openSubcategory: itemId => dispatch(openMegaMenuSubcategory(itemId, ownProps.isLoggedIn)),
  }),
)(SlideOutNav);
