import React from 'react';
import { oneOfType, string, element, arrayOf } from 'prop-types';

import { logger } from 'logger';

export class SimpleErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { section } = this.props;
    // TODO log to Elastic APM? Logger can handle that transparently?
    logger.error({
      payload: {
        message: `Error boundary: ${section}`,
        errorDetails: {
          error: error?.message,
          info: errorInfo,
          section,
        },
      },
    });
  }

  render() {
    const { hasError } = this.state;
    const { children, errorComponent } = this.props;
    if (hasError) {
      return errorComponent || null;
    }

    return children;
  }
}
SimpleErrorBoundary.propTypes = {
  children: oneOfType([element, arrayOf(element)]).isRequired,
  errorComponent: oneOfType([element, arrayOf(element)]),
  section: string.isRequired,
};

SimpleErrorBoundary.defaultProps = {
  errorComponent: null,
};
