import React from 'react';
import propTypes from 'prop-types';
import RichText from 'cms-components/RichText';
import NavigationLinks from 'cms-components/NavigationLinks';
import { CONTAINER, NAVIGATION_LINK, RICH_TEXT } from 'constants/aem-component-names';

export const MiddleMegaMenuExperienceFragment = ({ location }) => {
  if (!location?.length) {
    return null;
  }

  const [containerComponent] = location;

  if (containerComponent.resourceType !== CONTAINER) {
    return null;
  }

  const {
    childComponents: [grid],
  } = containerComponent;

  const { childComponents } = grid;

  return (
    <div id="mega-menu-second-column" data-testid="middle-mega-menu">
      {childComponents.map(component => {
        if (component.resourceType === RICH_TEXT) {
          return <RichText data={{ ...component, noMargin: true }} key={component.componentId} />;
        }
        if (component.resourceType === NAVIGATION_LINK) {
          return <NavigationLinks {...component} key={component.componentId} />;
        }
        return null;
      })}
    </div>
  );
};

MiddleMegaMenuExperienceFragment.defaultProps = {
  location: [],
};

MiddleMegaMenuExperienceFragment.propTypes = {
  location: propTypes.arrayOf(
    propTypes.shape({
      resourceType: propTypes.string,
    }),
  ),
};
