import React from 'react';
import { Address } from 'services/address/types';
import { SlotReservation } from 'services/slotOrchestration/types';

export type BranchAddress = {
  name: string;
} & Pick<Address, 'line1' | 'postalCode' | 'town'>;

export type SlotContextState = {
  loading: boolean;
  booked: boolean;
  details: SlotReservation | null;
  error: boolean;
  address: (Address | BranchAddress) | null;
};

export type SlotContextValue = {
  slotContext: SlotContextState;
  setSlotContext: (arg: SlotContextState) => void;
};

const SlotContext = React.createContext<SlotContextValue | undefined>(undefined);

export { SlotContext };
