import React, { useRef, useCallback, useEffect } from 'react';
import { bool, func } from 'prop-types';
import classNames from 'classnames';
import { useAnalytics } from '@johnlewispartnership/wtr-website-analytics';
import { ChevronDown } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { KEY_ENTER, KEY_ESCAPE } from 'constants/keys';
import useOnClickOutside from 'hooks/use-on-click-outside';
import { setBodyNoScroll } from 'utils/scroll';
import { formatMenuClickEvent } from 'utils/tracking';
import MegaMenu from 'components/MegaMenu/Menus';
import styles from './DropdownNav.module.scss';

const DropDownNav = ({ isOpen, onClose, onOpen, scrolled }) => {
  const menuButtonRef = useRef();
  const navigationRef = useRef();
  const menuRef = useRef();
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    setBodyNoScroll(isOpen);
  }, [isOpen]);

  const handleClickOpener = (event, taxonomyRoot) => {
    event.preventDefault();
    if (isOpen) {
      onClose();
    } else {
      onOpen(taxonomyRoot);
      trackEvent(formatMenuClickEvent('Groceries'));
    }
  };

  const handleKeyDownOpener = (event, taxonomyRoot) => {
    if (event.keyCode === KEY_ENTER) {
      handleClickOpener(event, taxonomyRoot);
      setTimeout(() => {
        if (navigationRef.current) navigationRef.current.focusFirst();
      }, 200);
    }
  };

  const handleClickToClose = useCallback(
    event => {
      if (isOpen) {
        event.stopPropagation();
        onClose();

        menuButtonRef?.current?.focus?.();
      }
    },
    [isOpen, onClose],
  );

  const handleClickOutside = useCallback(
    event => {
      if (isOpen) {
        event.stopPropagation();
        onClose();
      }
    },
    [isOpen, onClose],
  );

  useOnClickOutside(menuRef, handleClickOutside);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      data-testid="DropDownNav"
      onKeyDown={event => {
        if (event.keyCode === KEY_ESCAPE) {
          handleClickToClose(event);
        }
      }}
    >
      <button
        aria-controls="megamenu"
        aria-expanded={isOpen}
        ref={menuButtonRef}
        aria-label="Toggle groceries menu"
        className={classNames(styles.btnMenu, {
          [styles.menuActive]: isOpen,
          [styles.scrolled]: scrolled,
        })}
        data-testid="site-header-browse-groceries"
        id="drop-down-nav"
        onClick={event => handleClickOpener(event, 'Groceries')}
        onKeyDown={event => handleKeyDownOpener(event, 'Groceries')}
        type="button"
      >
        Groceries
        <ChevronDown className="icon" size="xsmall" />
      </button>
      <div ref={menuRef}>
        {isOpen && (
          <MegaMenu
            navigationRef={navigationRef}
            scrolled={scrolled}
            handleClickToClose={handleClickToClose}
          />
        )}
      </div>
    </div>
  );
};

DropDownNav.displayName = 'DropDownNav';

DropDownNav.propTypes = {
  isOpen: bool,
  onClose: func.isRequired,
  onOpen: func.isRequired,
  scrolled: bool,
};

DropDownNav.defaultProps = {
  isOpen: false,
  scrolled: false,
};

export default DropDownNav;
