import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export function formatSlotTime(time: string) {
  // In case time is an empty string (or null/undefined when called from js code)
  if (!time) return undefined;

  const isIsoTime = time.includes('T');
  const dayjsTime = isIsoTime ? dayjs.utc(time).tz('GB') : dayjs(time, 'HH:mm:ss');
  const formattedTime = dayjsTime.format('hA');

  if (!isIsoTime && dayjsTime.hour() === 23 && dayjsTime.minute() === 30) return '12AM';

  return formattedTime;
}
