import { UPDATE_SEARCH_TERM } from 'redux/modules/search/actions/types';
import initialState from './initial-state';

export default function search(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_SEARCH_TERM:
      return {
        ...state,
        origin: action.origin,
        queryParams: {
          ...state.queryParams,
          category: null,
          filterTags: [],
          searchTags: [],
          searchTerm: action.searchTerm,
          sortBy: '',
        },
      };

    default:
      return state;
  }
}
