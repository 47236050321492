/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Bin } from '@johnlewispartnership/wtr-ingredients/dist/foundations/icons';
import { useAnalytics } from '@johnlewispartnership/wtr-website-analytics';
import styles from 'components/Search/SearchHistory/SearchHistory.module.scss';
import {
  deleteSearchHistory,
  getSavedSearchTerms,
  removeSavedSearchTerm,
} from 'components/Search/SearchForm/save-search-term';
import { KEY_DOWN, KEY_UP, KEY_ENTER, KEY_DELETE, KEY_TAB } from 'constants/keys';
import { refType } from 'constants/types/ref';

import ScreenReaderAnnouncement from 'components/ScreenReaderAnnouncement';

const NUMBER_OF_TERMS_TO_SHOW = 6;

function SearchHistory({
  inputRef,
  searchType,
  onSelectTerm,
  onSelectedChange,
  homepage,
  className,
}) {
  const { trackEvent } = useAnalytics();

  const [searchTerms, setSearchTerms] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleTermClick = useCallback(
    term => {
      onSelectTerm(term);

      trackEvent({
        event: 'search_history_item_click',
        eventAction: 'SearchType:SearchHistory',
        eventCategory: 'Search',
        eventLabel: `SearchTerm:${term}`,
        eventValue: undefined,
      });
    },
    [onSelectTerm, trackEvent],
  );

  const handleTermRemove = useCallback(
    (event, term) => {
      event.stopPropagation();
      removeSavedSearchTerm(term, searchType);

      const savedSearchTerms = getSavedSearchTerms(NUMBER_OF_TERMS_TO_SHOW, searchType);
      setSearchTerms(savedSearchTerms);

      trackEvent({
        event: 'search_history_item_remove',
        eventAction: 'SearchType:RemoveSearchHistoryTerm',
        eventCategory: 'Search',
        eventLabel: `SearchTerm:${term}`,
        eventValue: undefined,
      });
    },
    [searchType, setSearchTerms, trackEvent],
  );

  const handleClearAllClick = () => {
    deleteSearchHistory(searchType);

    setSearchTerms(getSavedSearchTerms(NUMBER_OF_TERMS_TO_SHOW, searchType));

    trackEvent({
      event: 'search_history_clear_all',
      eventAction: 'SearchType:SearchHistoryReset',
      eventCategory: 'Search',
      eventLabel: `SearchHistoryReset`,
      eventValue: undefined,
    });
  };

  const handleTermKeyDown = useCallback(
    event => {
      switch (event.keyCode) {
        case KEY_DOWN: {
          if (selectedIndex === null) {
            setSelectedIndex(0);
            onSelectedChange(0);
            break;
          }

          const newSelectedIndex = selectedIndex + 1;
          if (newSelectedIndex < searchTerms.length) {
            setSelectedIndex(newSelectedIndex);
            onSelectedChange(newSelectedIndex);
          }
          break;
        }
        case KEY_UP: {
          const newSelectedIndex = selectedIndex - 1;
          if (newSelectedIndex >= 0) {
            setSelectedIndex(newSelectedIndex);
            onSelectedChange(newSelectedIndex);
          } else {
            setSelectedIndex(null);
            onSelectedChange(null);
          }
          break;
        }
        case KEY_ENTER: {
          if (selectedIndex !== null) {
            handleTermClick(searchTerms[selectedIndex]);
          }
          break;
        }
        case KEY_TAB: {
          setSearchTerms([]);
          break;
        }
        case KEY_DELETE: {
          if (selectedIndex !== null) {
            handleTermRemove(event, searchTerms[selectedIndex]);
          }
          break;
        }
        default:
      }
    },
    [
      selectedIndex,
      searchTerms,
      onSelectedChange,
      setSelectedIndex,
      setSearchTerms,
      handleTermClick,
      handleTermRemove,
    ],
  );

  useEffect(() => {
    setSearchTerms(getSavedSearchTerms(NUMBER_OF_TERMS_TO_SHOW, searchType));
  }, [setSearchTerms, searchType]);

  useEffect(() => {
    const nodeRef = inputRef?.current;
    if (nodeRef && nodeRef?.addEventListener) {
      nodeRef.addEventListener('keydown', handleTermKeyDown);
    }

    return () => {
      if (nodeRef && nodeRef?.removeEventListener) {
        nodeRef.removeEventListener('keydown', handleTermKeyDown);
      }
    };
  }, [inputRef, handleTermKeyDown]);

  return searchTerms?.length ? (
    <div
      className={classNames(styles.searchHistoryContainer, {
        [styles.homepage]: homepage,
        [className]: !!className,
      })}
    >
      <div className={styles.label} id="search-history-label">
        <span>Your recent searches</span>
        <button
          className={styles.clearAll}
          data-testid="search-history-clear-all"
          onClick={handleClearAllClick}
          type="button"
        >
          Clear all
        </button>
      </div>
      <ul aria-labelledby="search-history-label" id="search-history" role="listbox">
        {searchTerms.map((term, index) => (
          <li
            aria-selected={index === selectedIndex}
            className={classNames({ [styles.selected]: index === selectedIndex })}
            data-testid="search-history-term"
            id="search-history-item"
            key={term}
            onClick={() => handleTermClick(term)}
            role="option"
          >
            <span className={styles.searchTerm}>{term}</span>

            <button
              className={styles.removeButton}
              data-testid="search-history-term-remove"
              onClick={event => handleTermRemove(event, term)}
              type="button"
            >
              <Bin size="medium" />
            </button>
          </li>
        ))}
      </ul>
      <ScreenReaderAnnouncement
        polite
        message={`You have: ${searchTerms.length} previous search terms found.`}
      />
    </div>
  ) : null;
}

SearchHistory.displayName = 'SearchHistory';

SearchHistory.propTypes = {
  onSelectedChange: PropTypes.func,
  onSelectTerm: PropTypes.func,
  homepage: PropTypes.bool,
  className: PropTypes.string,
  inputRef: refType,
  searchType: PropTypes.string,
};

SearchHistory.defaultProps = {
  onSelectedChange: () => {},
  onSelectTerm: () => {},
  homepage: false,
  className: null,
  inputRef: null,
  searchType: null,
};

export default SearchHistory;
