/* Guide

  If you want to format a price value as:
  * pounds (e.g. £0.90), use formatAsPounds.
  * pounds - if £1 or more - or pence - if less than a £1 (e.g. 90p), use formatAsPoundsOrPence.
  * pounds or pence, or £0.00 if negative, use formatAsPositivePoundsOrPence.
*/

function toNumber(price: string | number): number {
  return typeof price === 'number' ? price : parseFloat(price);
}

function toPositiveNumber(price: number | string): number {
  return Math.max(0, toNumber(price));
}

export const formatAsPounds = (price: number | string = 0) => {
  const num = toNumber(price);
  const amount = Math.abs(num);

  return `${num < 0 ? '−' : ''}£${amount.toFixed(2)}`;
};

export const formatAsPoundsOrPence = (price: number | string = 0) => {
  const num = toNumber(price);
  const amount = Math.abs(num);
  const pence = Math.round(amount * 100);

  if (pence === 0) return '';
  if (pence < 100) return `${num < 0 ? '−' : ''}${pence}p`;
  return formatAsPounds(price);
};

export const formatAsPositivePoundsOrPence = (price: number | string | null = null) => {
  if (price === null) return '';

  const positivePrice = toPositiveNumber(price);
  const pence = Math.round(positivePrice * 100);

  return pence ? formatAsPoundsOrPence(positivePrice) : '£0.00';
};
