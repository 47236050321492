import { useContext } from 'react';
import { ShoppingContext } from './ShoppingContext';

const useShoppingContext = () => {
  const shoppingContext = useContext(ShoppingContext);

  if (shoppingContext === undefined) {
    throw new Error('useShoppingContext must be used within a ShoppingContextProvider');
  }

  return shoppingContext;
};

export default useShoppingContext;
