import React, { Fragment, useCallback, useMemo, useEffect, useRef, useState } from 'react';
import { arrayOf, bool, object, func, number, oneOfType, shape, string } from 'prop-types';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import classNames from 'classnames';
import { useSpring, useTransition, a } from 'react-spring';
import {
  Burger,
  ChevronLeft,
  Close,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { MY_DETAILS, SLOT_BUTTON, SEASONAL, SIGN_IN } from 'constants/categoryIds';
import { KEY_ENTER, KEY_ESCAPE } from 'constants/keys';
import { cmsMenuLinksType } from 'constants/types/cms-menu';
import MenuFocusProvider from 'components/MegaMenu/MenuFocusProvider';
import MiddleMegaMenuExperienceFragment from 'components/MegaMenu/MiddleMegaMenu';
import usePrevious from 'hooks/use-previous';
import { singleResizeWatcher } from 'utils/single-event-watcher';
import { handleLoginWithRedirect } from 'utils/login';

import FocusTrap from 'components/FocusTrap';
import { usePreloadedData } from 'contexts/PreloadedData';
import MenuExperienceFragmentClickHandler from 'cms-components/MenuExperienceFragmentClickHandler/MenuExperienceFragmentClickHandler';
import { SeasonalMenuExperienceFragment } from 'cms-components/ExperienceFragments/SeasonalMenuExperienceFragment';

import MenuLink from './MenuLink';
import MenuCard from './MenuCard';

import styles from './SlideOutNav.module.scss';
import seasonalStyles from '../SeasonalMenu/SeasonalMenuPanels/SeasonalMenuPanels.module.scss';

import {
  formatSlotButtonText,
  formatSlotButtonScreenReaderLabel,
} from '../SlotButton/utils/format-slot-button';

export const SlideOutNav = ({
  closeMegaMenu,
  closeSubcategory,
  showContactAddressNotPresentNotification,
  level,
  menus,
  navigateFromMegaMenu,
  previousTitle,
  open,
  openMegaMenu,
  openSubcategory,
  specialistShopLinks,
  isSlotBooked,
  slotDetails,
  slotLoading,
}) => {
  const buttonRef = useRef();
  const menuRef = useRef();
  const navigationRef = useRef();
  const maxMenus = 3;

  const [deeper, setDeeper] = useState(true);
  const [trapFocus, setTrapFocus] = useState(false);
  const closing = usePrevious(open) && !open;
  const [hasBeenOpenBefore, setHasBeenOpenBefore] = useState(false);

  useEffect(() => {
    if (!hasBeenOpenBefore && open) {
      setHasBeenOpenBefore(true);
    }
  }, [hasBeenOpenBefore, open]);

  const animationConfig = useMemo(
    () => ({
      duration: 200,
      easing: t => t * t * t, // ease in cubic
    }),
    [],
  );

  const menuTransition = useTransition([menus], {
    config: animationConfig,
    enter: { transform: 'translateX(0%)' },
    from: { position: 'absolute', transform: deeper ? 'translateX(100%)' : 'translateX(-100%)' },
    key: level,
    leave: closing
      ? {
          // prevent current menu sliding away and root menu sliding in while nav is closing
          transform: 'translateX(0)',
          zIndex: 12,
        }
      : { transform: deeper ? 'translateX(-100%)' : 'translateX(100%)' },
  });

  const navSpring = useSpring({
    config: animationConfig,
    from: { display: 'none' },
    to: open
      ? [{ display: 'block' }, { transform: 'translateX(0%)' }]
      : [{ display: 'block', transform: 'translateX(-100%)' }, { display: 'none' }],
  });

  const overlaySpring = useSpring({
    config: animationConfig,
    from: { display: 'none' },
    to: open
      ? [{ display: 'block' }, { opacity: 1 }]
      : [{ display: 'block', opacity: 0 }, { display: 'none' }],
  });

  const focusFirst = useCallback(() => {
    setTimeout(() => navigationRef.current?.focusFirst(), animationConfig.duration);
  }, [animationConfig, navigationRef]);

  const focusId = useCallback(
    id => {
      setTimeout(() => navigationRef.current?.focusId(id), animationConfig.duration);
    },
    [animationConfig, navigationRef],
  );

  const scrollMenuToTop = useCallback(() => {
    setTimeout(() => {
      if (menuRef.current) menuRef.current.scrollTop = 0;
    }, 0);
  }, [menuRef]);

  useEffect(() => {
    if (open) {
      disableBodyScroll(menuRef.current);
      scrollMenuToTop();
    }
    // this delay is necessary to avoid the scroll bug on mobile iOS
    // looks like because of the animated menu the <FocusTrap> fails
    // because there are no focused elements
    setTimeout(() => {
      setTrapFocus(open);
    }, 150);
    return clearAllBodyScrollLocks;
  }, [open, scrollMenuToTop]);

  const nextLevel = useCallback(
    (itemId, focus = true) => {
      openSubcategory(itemId);
      if (level < maxMenus) {
        setDeeper(true);
        scrollMenuToTop();
        if (focus) focusFirst();
      }
    },
    [focusFirst, level, openSubcategory, scrollMenuToTop],
  );

  const previousLevel = useCallback(() => {
    if (level > 0) {
      setDeeper(false);
      closeSubcategory();
      scrollMenuToTop();
      focusId(menus[0].id);
    }
  }, [focusId, closeSubcategory, level, menus, scrollMenuToTop, setDeeper]);

  const handleClick = (event, itemId) => {
    if (itemId === SIGN_IN) {
      handleLoginWithRedirect(event);
    }
    nextLevel(itemId, false);
  };

  const handleMenuButtonKeyDown = useCallback(
    event => {
      if (event.keyCode === KEY_ENTER) focusFirst();
    },
    [focusFirst],
  );

  const handleKeyDown = useCallback(
    event => {
      if (event.keyCode === KEY_ESCAPE) {
        closeMegaMenu();
        event.preventDefault();
        event.stopPropagation();
      }
      if (event.keyCode === KEY_ENTER) focusFirst();
    },
    [closeMegaMenu, focusFirst],
  );

  const handleClickBack = useCallback(() => {
    setDeeper(false);
    closeSubcategory();
  }, [closeSubcategory, setDeeper]);

  useEffect(() => {
    const width = window.innerWidth;
    if (open)
      // eslint-disable-next-line consistent-return
      singleResizeWatcher(() => {
        const newWidth = window.innerWidth;
        if (width !== newWidth) {
          return closeMegaMenu();
        }
      });
  }, [open, closeMegaMenu]);

  const { preloadedData } = usePreloadedData();

  const hasCmsMenus =
    !!preloadedData?.middleMegaMenu?.content?.locations?.middleMegaMenu ||
    !!specialistShopLinks?.length;

  const seasonalMenu = preloadedData?.seasonalMenu?.content;
  const seasonalMenuTitle = seasonalMenu?.pageTitle?.text;
  const seasonalMenuContent = seasonalMenu?.locations?.seasonalMenu;

  const isSeasonalLevel = level === 1 && menus[0]?.id === SEASONAL;
  const isCmsLevel = level === 1 && !isSeasonalLevel;
  const showSeasonalMenu = !!seasonalMenuTitle && !!seasonalMenuContent;

  const slotButtonText = formatSlotButtonText({
    isSlotBooked,
    slotEndDateTime: slotDetails?.endDateTime,
    slotStartDateTime: slotDetails?.startDateTime,
  });
  const slotButtonLabel = formatSlotButtonScreenReaderLabel({
    isSlotBooked,
    slotEndDateTime: slotDetails?.endDateTime,
    slotStartDateTime: slotDetails?.startDateTime,
    slotType: slotDetails?.slotType,
  });
  const renderSlotButton = useCallback(
    () =>
      slotLoading ? (
        <span role="status" aria-live="polite">
          Loading
        </span>
      ) : (
        <span aria-label={isSlotBooked ? slotButtonLabel : null}>{slotButtonText}</span>
      ),
    [isSlotBooked, slotButtonLabel, slotButtonText, slotLoading],
  );

  const renderSpecialistShopLinks = useCallback(
    () =>
      specialistShopLinks.map(({ id, card, name, newWindow, subtext, href }) => (
        <Fragment key={id}>
          {card && (
            <MenuCard
              id={id}
              level={level}
              menus={menus}
              name={name}
              description={subtext}
              onClick={() => {
                navigateFromMegaMenu(level, name, id);
              }}
              path={href}
              newWindow={newWindow}
            />
          )}
        </Fragment>
      )),
    [level, menus, navigateFromMegaMenu, specialistShopLinks],
  );

  const animatedMenus = menuTransition((style, item) => (
    <a.div
      style={style}
      className={classNames(styles.menuLevel, {
        [styles.cmsLevel]: isCmsLevel,
      })}
    >
      <MenuFocusProvider onLeftKey={previousLevel} onRightKey={nextLevel} ref={navigationRef}>
        {item &&
          item.map(({ categoryIds = [], id, name }) => (
            <Fragment key={id}>
              <h4 className={styles.navTitle}>
                {showSeasonalMenu && isSeasonalLevel ? seasonalMenuTitle : name}
              </h4>
              {categoryIds.map(categoryId => {
                let linkProps = {
                  id: categoryId,
                  maxMenus,
                };
                if (typeof categoryId === 'object') {
                  linkProps = {
                    ...linkProps,
                    ...categoryId,
                  };
                }
                if (isSeasonalLevel && level === 1) {
                  return null;
                }
                if (!showSeasonalMenu && categoryId === SEASONAL && level === 0) {
                  return null;
                }

                return (
                  <MenuLink
                    {...linkProps}
                    key={linkProps.id}
                    showWarningIcon={
                      categoryId === MY_DETAILS && showContactAddressNotPresentNotification
                    }
                    onClick={event => handleClick(event, linkProps.id)}
                    seasonalMenuTitle={categoryId === SEASONAL && seasonalMenuTitle}
                  >
                    {categoryId === SLOT_BUTTON && renderSlotButton()}
                  </MenuLink>
                );
              })}
            </Fragment>
          ))}
        {isCmsLevel && hasCmsMenus && (
          <div className={styles.middleMenu}>
            <MenuExperienceFragmentClickHandler
              trackingPrefix="MiddleMegaMenu"
              type="mobile"
              onClose={closeMegaMenu}
            >
              <MiddleMegaMenuExperienceFragment
                location={preloadedData?.middleMegaMenu?.content?.locations?.middleMegaMenu}
              />
            </MenuExperienceFragmentClickHandler>
            {renderSpecialistShopLinks()}
          </div>
        )}
        {isSeasonalLevel && (
          <div
            className={classNames(
              seasonalStyles.nav,
              seasonalStyles.panelWrapper,
              seasonalStyles.open,
            )}
          >
            <MenuExperienceFragmentClickHandler
              trackingPrefix="Seasonal"
              type="mobile"
              onClose={closeMegaMenu}
            >
              <SeasonalMenuExperienceFragment content={seasonalMenuContent} />
            </MenuExperienceFragmentClickHandler>
          </div>
        )}
        {level === 0 && <div className={styles.cmsLinks}>{renderSpecialistShopLinks()}</div>}
      </MenuFocusProvider>
    </a.div>
  ));

  const menuButtonComponent = useMemo(
    () => (
      <button
        aria-controls="slide-out-navigation"
        aria-expanded={open}
        aria-label="Toggle shop menu"
        className={classNames(
          styles.menuButton,
          'visible-xs-block visible-sm-block visible-md-block',
          {
            [styles.hide]: open,
            // [styles.CANotPresent]: showContactAddressNotPresentNotification,
          },
        )}
        data-testid="small-screen-menu-button"
        onClick={openMegaMenu}
        onKeyDown={handleMenuButtonKeyDown}
        ref={buttonRef}
        type="button"
      >
        <Burger data-testid="BurgerIcon" />
      </button>
    ),
    [open, openMegaMenu, handleMenuButtonKeyDown, buttonRef],
  );

  return (
    <FocusTrap active={trapFocus}>
      <div
        className={styles.menuWrapper}
        data-testid="small-screen-menu-wrapper"
        onKeyDown={handleKeyDown}
        role="presentation"
      >
        {menuButtonComponent}
        <a.div
          data-testid="small-screen-menu"
          id="slide-out-navigation"
          ref={menuRef}
          className={styles.menu}
          style={hasBeenOpenBefore ? navSpring : { display: 'none' }}
        >
          <div className={styles.topWrapper}>
            <div className={styles.closeWrapper}>
              <button
                className={styles.closeButton}
                data-testid="close-button"
                onClick={closeMegaMenu}
                type="button"
              >
                <span className={styles.srOnly}>Close</span>
                <Close size="small" />
              </button>
            </div>
            {level > 0 && (
              <button
                className={classNames(styles.iconButton, styles.backButton)}
                onClick={handleClickBack}
                data-testid="back-button"
                type="button"
              >
                <span className={styles.srOnly}>Back up a level</span>
                <ChevronLeft />
                {previousTitle}
              </button>
            )}
          </div>
          {animatedMenus}
        </a.div>
        <a.div
          className={styles.menuOverlay}
          data-testid="small-screen-menu-overlay"
          onClick={closeMegaMenu}
          role="presentation"
          style={hasBeenOpenBefore ? overlaySpring : { display: 'none' }}
        />
      </div>
    </FocusTrap>
  );
};

SlideOutNav.propTypes = {
  closeMegaMenu: func.isRequired,
  closeSubcategory: func.isRequired,
  showContactAddressNotPresentNotification: bool,
  level: number,
  menus: arrayOf(
    shape({
      id: string,
      name: string,
      categoryIds: arrayOf(oneOfType([shape({ id: string }), string])),
    }),
  ),
  navigateFromMegaMenu: func.isRequired,
  open: bool,
  openMegaMenu: func.isRequired,
  openSubcategory: func.isRequired,
  previousTitle: string,
  specialistShopLinks: cmsMenuLinksType,
  isSlotBooked: bool,
  slotDetails: object,
  slotLoading: bool,
};

SlideOutNav.defaultProps = {
  showContactAddressNotPresentNotification: false,
  level: 0,
  menus: [],
  open: false,
  previousTitle: undefined,
  specialistShopLinks: [],
  isSlotBooked: false,
  slotDetails: {},
  slotLoading: false,
};
