import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useAnalytics } from '@johnlewispartnership/wtr-website-analytics';
import { useAuthentication } from '@johnlewispartnership/wtr-website-authentication/dist/context';
import {
  ChevronDown,
  Document,
  DocumentAdd,
  HeartInactive,
  MyWaitrose,
  User,
  Warning,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { logger } from 'logger';
import { urls } from 'constants/urls';
import AnchorLink from 'components/AnchorLink';
import SignOutButton from 'components/AuthenticationAction/SignOut/Button';

import useOnClickOutside from 'hooks/use-on-click-outside';

import styles from './MyAccount.scss';

export const myAccountLabels = {
  myWaitroseLoyalty: 'myWaitrose loyalty',
  lists: 'Lists',
  myDetails: 'My details',
  myOrders: 'My orders',
  favourites: 'Favourites',
  signOut: 'Sign out',
};

const logAnalytics = (button, showContactAddressNotPresentNotification, trackEvent) => {
  const getDataLayerCAText = notificationPresent => {
    if (notificationPresent) {
      switch (button) {
        case 'Header':
          return 'YES: Red Badge';
        case myAccountLabels.myDetails:
          return 'YES: Red Exclamation';
        default:
          return undefined;
      }
    }
    return undefined;
  };

  trackEvent({
    event: 'account_menu_click',
    my_account_menu: {
      button,
    },
    prompt: {
      contact_address: getDataLayerCAText(showContactAddressNotPresentNotification),
    },
    temporary: true,
  });
};

const MyAccount = ({
  homePagePlacement,
  currentPageIsGroceriesHomePage,
  showContactAddressNotPresentNotification,
}) => {
  const [hidden, setHidden] = useState(true);
  const insideRef = useRef();

  const { trackEvent } = useAnalytics();

  const pushMenuClick = useCallback(
    buttonLabel => {
      trackEvent({
        event: 'click_megamenu',
        mega_menu: {
          button_clicked: buttonLabel,
        },
      });
    },
    [trackEvent],
  );

  const toggleVisibility = useCallback(() => {
    setHidden(!hidden);
  }, [hidden]);

  const handleButtonClick = () => {
    if (hidden) {
      logAnalytics('Header', showContactAddressNotPresentNotification, trackEvent);
    }
    toggleVisibility();
  };

  useOnClickOutside(insideRef, () => {
    if (!hidden) {
      setHidden(true);
    }
  });

  const handleBlur = useCallback(() => {
    if (!hidden) {
      toggleVisibility();
    }
  }, [hidden, toggleVisibility]);

  const handleFocus = useCallback(() => {
    if (hidden) {
      toggleVisibility(false);
    }
  }, [hidden, toggleVisibility]);

  const handleLinkClick = useCallback(
    button => {
      toggleVisibility();
      pushMenuClick(`My Account: ${button}`);
      logAnalytics(button, showContactAddressNotPresentNotification, trackEvent);
    },
    [pushMenuClick, showContactAddressNotPresentNotification, toggleVisibility, trackEvent],
  );

  const { logout } = useAuthentication();

  const logoutUser = async () => {
    try {
      await logout('/');
    } catch (error) {
      logger.error({
        payload: {
          message: `Logout error`,
          errorDetails: {
            error: error.message,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (showContactAddressNotPresentNotification && currentPageIsGroceriesHomePage) {
      trackEvent({
        event: 'contact_address_red_badge_shown',
        temporary: true,
      });
    }
  }, [currentPageIsGroceriesHomePage, showContactAddressNotPresentNotification, trackEvent]);

  return (
    <nav
      ref={insideRef}
      className={styles.accountActionWrapper}
      id="authenticated"
      data-testid="my-account-menu"
    >
      <button
        aria-controls="account-actions"
        aria-expanded={!hidden}
        className={classNames(styles.button, homePagePlacement ? styles.buttonHome : '')}
        data-testid="my-account-button"
        id="account-details"
        onClick={handleButtonClick}
        tabIndex="-1"
        type="button"
      >
        <span
          className={classNames(styles.iconWrapper, {
            [styles.CANotPresent]: showContactAddressNotPresentNotification,
          })}
          data-testid="my-account-icon-wrapper"
        >
          <User className={styles.buttonIcon} />
        </span>
        <span className={homePagePlacement ? styles.buttonText : ''}>My account</span>
        <ChevronDown className={styles.chevronIcon} size="xsmall" />
      </button>
      <div className={classNames(styles.accountPopup, { 'sr-only': hidden })}>
        <ul className={styles.accountActions} id="account-actions">
          <li>
            <AnchorLink
              data-testid="my-details-link"
              id="my-details"
              onBlur={handleBlur}
              onClick={() => handleLinkClick(myAccountLabels.myDetails)}
              onFocus={handleFocus}
              to={urls.myDetailsPage}
            >
              <User className={styles.accountMenuIcon} size="medium" />
              {myAccountLabels.myDetails}
              {showContactAddressNotPresentNotification && (
                <Warning data-testid="warningIcon" className={styles.iconNoCAWarning} />
              )}
            </AnchorLink>
          </li>
          {homePagePlacement && (
            <>
              <li>
                <AnchorLink
                  id="site-header-my-orders"
                  to={urls.myOrdersPage}
                  data-testid="site-header-my-orders"
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onClick={() => handleLinkClick(myAccountLabels.myOrders)}
                >
                  <Document className={classNames(styles.accountMenuIcon, styles.documentNormal)} />
                  {myAccountLabels.myOrders}
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  id="site-header-favourites"
                  to={urls.favourites}
                  data-testid="site-header-favourites"
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onClick={() => handleLinkClick(myAccountLabels.favourites)}
                >
                  <HeartInactive className={styles.accountMenuIcon} />
                  {myAccountLabels.favourites}
                </AnchorLink>
              </li>
            </>
          )}
          <li>
            <AnchorLink
              data-testid="lists-link"
              onBlur={handleBlur}
              onClick={() => handleLinkClick(myAccountLabels.lists)}
              onFocus={handleFocus}
              to={urls.lists}
            >
              <DocumentAdd className={styles.accountMenuIcon} size="medium" />
              {myAccountLabels.lists}
            </AnchorLink>
          </li>
          {!homePagePlacement && (
            <li>
              <AnchorLink
                data-testid="my-waitrose-link"
                onBlur={handleBlur}
                onClick={() => handleLinkClick(myAccountLabels.myWaitroseLoyalty)}
                onFocus={handleFocus}
                to={urls.myWaitrosePage}
              >
                <MyWaitrose className={styles.accountMenuIcon} size="medium" />
                {myAccountLabels.myWaitroseLoyalty}
              </AnchorLink>
            </li>
          )}
          <li className={styles.signOut}>
            <SignOutButton
              data-testid="account-sign-out"
              className={styles.signOutButton}
              onClick={() => handleLinkClick(myAccountLabels.signOut)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              width="full"
              loggedIn
              onLogoutUser={logoutUser}
            />
          </li>
        </ul>
      </div>
    </nav>
  );
};

MyAccount.displayName = 'MyAccount';

MyAccount.propTypes = {
  homePagePlacement: PropTypes.bool,
  currentPageIsGroceriesHomePage: PropTypes.bool,
  showContactAddressNotPresentNotification: PropTypes.bool,
};

MyAccount.defaultProps = {
  homePagePlacement: false,
  currentPageIsGroceriesHomePage: false,
  showContactAddressNotPresentNotification: false,
};

export default MyAccount;
