import { SET_MEGAMENU_HISTORY } from 'redux/modules/mega-menu/actions/types';

import { getMegaMenuHistory } from 'redux/modules/mega-menu/selectors';

import setMegaMenuActiveItem from 'redux/modules/mega-menu/actions/set-mega-menu-active-item';
import setMegaMenuRoot from 'redux/modules/mega-menu/actions/set-mega-menu-root';

export default () => (dispatch, getState) => {
  const state = getState();
  const history = getMegaMenuHistory(state);
  const { activeItem, menuRoot } = history[history.length - 1];

  // push updated history to state
  dispatch({
    history: history.slice(0, history.length - 1),
    type: SET_MEGAMENU_HISTORY,
  });
  // step menuRoot backwards
  dispatch(setMegaMenuRoot(menuRoot));
  // set new activeItem
  dispatch(setMegaMenuActiveItem(activeItem));
};
