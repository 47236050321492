import { connect } from 'react-redux';
import { getAutoSuggestActive } from 'redux/modules/auto-suggest/selectors';
import autoSuggestClear from 'redux/modules/auto-suggest/actions/auto-suggest-clear';
import autoSuggestSearch from 'redux/modules/auto-suggest/actions/auto-suggest-search';
import { updateSearchTerm } from 'redux/modules/search/actions/update-search-term';
import { getCookiePreferences } from 'utils/cookie';
import SearchForm from './SearchForm';

export default connect(
  state => ({
    autoSuggestActive: getAutoSuggestActive(state),
    functionalCookieConsent: getCookiePreferences().functionalCookiesAccepted,
    // TODO once we handle PLP this has to be implemented again
    initialValue: '',
    // TODO once searchAndBrowse is migrated this should be implemented again
    loading: false,
  }),
  {
    // TODO implement the real autoSeggestSearch once we've api calls implemented, probably it would be thunk
    onChange: autoSuggestSearch,
    onClear: autoSuggestClear,
    onSubmit: updateSearchTerm,
  },
)(SearchForm);
