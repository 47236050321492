import { isWindowAvailable } from 'utils/browser';
import { getFeatureFlags } from 'utils/featureFlags';

export const SEARCH_HISTORY_NAME = 'wtr_search_history';
const LIMIT = 60;

const decorate = fn => (arg, searchType) =>
  fn(arg, `wtr_${searchType ? `${searchType}_` : ''}search_history`);

export const getSavedSearchTerms = decorate((quantity, searchName) => {
  if (!isWindowAvailable || !getFeatureFlags().searchHistory) return [];

  try {
    const parsedValue = JSON.parse(window.localStorage.getItem(searchName));
    if (!parsedValue || !Array.isArray(parsedValue)) {
      throw new Error('Not a valid search history value');
    }
    return parsedValue.slice(0, quantity).map(({ t }) => t);
  } catch (e) {
    window.localStorage.removeItem(searchName);
  }

  return [];
});

export const saveSearchTerm = decorate((term, searchName) => {
  if (!isWindowAvailable || !getFeatureFlags().searchHistory || !term.length) return;

  let searchHistory = [];
  try {
    const parsedValue = JSON.parse(window.localStorage.getItem(searchName));
    if (!parsedValue || !Array.isArray(parsedValue)) {
      throw new Error('Not a valid search history value');
    }

    searchHistory = [...parsedValue];

    searchHistory = searchHistory.filter(({ t }) => `${t}`.toLowerCase() !== term.toLowerCase());

    if (searchHistory.length >= LIMIT) {
      searchHistory = searchHistory.slice(0, LIMIT - 1);
    }
  } catch (e) {
    window.localStorage.removeItem(searchName);
  }

  searchHistory.unshift({ t: term });

  window.localStorage.setItem(searchName, JSON.stringify(searchHistory));
});

export const removeSavedSearchTerm = decorate((term, searchName) => {
  if (!isWindowAvailable) return;

  try {
    const parsedValue = JSON.parse(window.localStorage.getItem(searchName));
    if (!parsedValue || !Array.isArray(parsedValue)) {
      throw new Error('Not a valid search history value');
    }
    window.localStorage.setItem(
      searchName,
      JSON.stringify(parsedValue.filter(({ t }) => t !== term)),
    );
  } catch (e) {
    window.localStorage.removeItem(searchName);
  }
});

export const deleteSearchHistory = searchType => {
  decorate((_, searchName) => {
    window.localStorage.removeItem(searchName);
  })(null, searchType);
};
