export function pathJoin(...parts: string[]): string {
  return parts
    .filter(empty => empty)
    .map((part, index, filteredParts) => {
      let trimmed = part;
      const isBase = index === 0;
      const isTerminal = index === filteredParts.length - 1;
      trimmed = !isBase && trimmed.startsWith('/') ? trimmed.substr(1) : trimmed;
      trimmed = !isTerminal && trimmed.endsWith('/') ? trimmed.slice(0, -1) : trimmed;
      return trimmed;
    })
    .join('/');
}
