import React from 'react';
import { ShoppingContext as ShoppingContextState } from 'services/shoppingContext/types';

export type { ShoppingContextState };

export type ShoppingContextValue = {
  shoppingContext: ShoppingContextState;
  setShoppingContext: (arg: ShoppingContextState) => void;
};

const ShoppingContext = React.createContext<ShoppingContextValue | undefined>(undefined);

export { ShoppingContext };
