import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import config from 'config';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

export const capitaliseFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export const capitaliseWords = (string, { ignore = [] } = {}) => {
  const isIgnored =
    typeof ignore === 'string' ? word => word === ignore : word => ignore.includes(word);

  return string
    .split(' ')
    .map(word => (isIgnored(word) ? word : capitaliseFirstLetter(word)))
    .join(' ');
};

export const formatGroceriesMenuNavigation = menuArray => {
  const separator = '|';

  if (!menuArray || menuArray.length === 0) return 'Shop';

  return menuArray.map((item, idx) => (idx === 0 ? `${item}` : `G${idx}:${item}`)).join(separator);
};

export const pluralize = (quantity, singular, includeQuantity = true) => {
  let result;

  if (quantity === 1) {
    result = `${singular}`;
  } else {
    switch (singular) {
      case 'person':
        result = 'people';
        break;
      default:
        result = `${singular}s`;
        break;
    }
  }

  if (includeQuantity) {
    result = `${quantity} ${result}`;
  }

  return result;
};

export const removeDiacritics = string => {
  if (!string) return '';

  const diacritics = 'ÀàÁáÄäÂâÈèÉéËëÊêÌìÍíÏïÎîÒòÓóÖöÔôÙùÚúÜüÛûÑñÇç·/_,:;';
  const nonDiacritics = 'AaAaAaAaEeEeEeEeIiIiIiIiOoOoOoOoUuUuUuUuNnCc------';
  let newString = string;

  for (let idx = 0, len = diacritics.length; idx < len; idx += 1) {
    newString = newString.replace(
      new RegExp(diacritics.charAt(idx), 'g'),
      nonDiacritics.charAt(idx),
    );
  }

  return newString;
};

export const sanitizeString = string => {
  const html = /(<([^>]+)>)/gi;
  const specialCharacters = /[^\w\s&'+-]|_/gi;
  const iphoneSpace = '\u2006';

  return (string || '').replace(html, '').replace(specialCharacters, '').replace(iphoneSpace, ' ');
};

export const sanitizeReqUrl = reqUrl => {
  const { pathname } = new URL(`${config.clientHost}${reqUrl}`);
  return pathname;
};

export const categoryNameToUrl = name =>
  (name || '')
    .replace(/\s+/g, '_')
    .replace(/&/g, 'and')
    .replace(/[^\w-]/g, '');

export function formatDate(value) {
  if (!value) return null;
  return dayjs.utc(value, 'DD-MM-YY').format();
}

export default function splitDateAndTimeFromIsoDateTime(isoString) {
  return isoString.replace(/Z|\+01:00/gi, '').split('T');
}

export const delimitWordsWithSpaces = title => title.replace(/-/g, ' ').replace(/_/g, ' ');
