import React from 'react';
import { bool, func, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';

import AnchorLink from 'components/AnchorLink';

import { linkImagesTexts } from 'constants/link-image-texts';

import { useMenuItemNavigation } from '../hooks/use-menu-item-navigation';
import styles from './MenuCard.module.scss';

const MenuCard = ({ bold, id, closeMegaMenu, name, description, onClick, path, newWindow }) => {
  const { handleClickItem, handleKeyDownItem, ref } = useMenuItemNavigation({
    id,
    closeMegaMenu,
    onClick,
    newWindow,
  });

  const testName = `${name ? `${name}-` : ''}menu-link-${id}`;
  const classes = classNames({
    [styles.bold]: bold,
  });

  return (
    <AnchorLink
      className={classNames(styles.menuCard, classes)}
      data-actiontype="redirect"
      data-origincomponent="mobile megamenu link"
      data-shortdescription={name}
      ref={ref}
      onClick={handleClickItem}
      onKeyDown={handleKeyDownItem}
      to={path}
      data-testid={testName}
      inNewTab={newWindow}
      aria-label={linkImagesTexts[name]?.ariaLabel}
    >
      <img
        className={styles.linkImg}
        alt={name}
        src={linkImagesTexts[name]?.image?.url}
        loading="lazy"
        height="80"
        width="80"
      />
      <span className={styles.textWrapper}>
        <span className={styles.title}>{name}</span>
        {description && <span className={styles.description}>{linkImagesTexts[name]?.text}</span>}
      </span>
    </AnchorLink>
  );
};

MenuCard.displayName = 'MenuCard';

MenuCard.propTypes = {
  bold: bool,
  description: string,
  id: string,
  closeMegaMenu: func.isRequired,
  name: string,
  newWindow: bool,
  onClick: func,
  path: oneOfType([string, shape({ hash: string, pathname: string, search: string })]),
};

MenuCard.defaultProps = {
  bold: false,
  description: undefined,
  id: undefined,
  name: undefined,
  newWindow: false,
  onClick: () => {},
  path: undefined,
};

export { MenuCard };
