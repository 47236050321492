// TODO change it for using breakpoints provided by ingredients

export const BREAKPOINTS = {
  xs: 0,
  sm: 544,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const BREAKPOINT_NAMES = ['xs', 'sm', 'md', 'lg', 'xl'];

export const COLUMNS_VISIBLE_AT_BREAKPOINT = {
  xs: 1,
  sm: 3,
  md: 3,
  lg: 4,
  xl: 6,
};

export const GRID_BASE = 12;
