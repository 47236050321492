import { useCallback, useEffect, useRef, useState } from 'react';
import SETTINGS from 'constants/settings';
import useOnClickOutside from 'hooks/use-on-click-outside';
import { KEY_ESCAPE } from 'constants/keys';
import { MAX_SEARCH_WORDS } from 'constants/search';
import { sanitizeString } from 'utils/format';
import { limitWords } from 'utils/limit-words';
import { getMinSuggestChars } from 'components/Search/utils/get-min-suggest-chars';
import { saveSearchTerm } from 'components/Search/SearchForm/save-search-term';

export const useSearchForm = ({
  autoSuggestActive,
  functionalCookieConsent,
  initialValue,
  loading,
  onChange,
  onClear,
  onSearch,
  onSubmit,
  url,
  searchType,
}) => {
  const inputRef = useRef();
  const insideRef = useRef();
  const [hasFocus, setHasFocus] = useState(false);
  const [hasHighlighted, setHasHighlighted] = useState(false);
  const [showSearchHistory, setShowSearchHistory] = useState(false);
  const [value, setValue] = useState(initialValue);
  const hasValue = !!value?.length;

  const focusInput = useCallback(focus => {
    const { current: input } = inputRef;
    if (input) {
      if (focus) input.focus();
      else input.blur();
    }
  }, []);

  const handleChange = useCallback(
    event => {
      if (!loading) {
        const eventValue = event.target.value;
        const searchTerm = limitWords(sanitizeString(eventValue || ''), MAX_SEARCH_WORDS);
        const isSameSearchTerm = value === searchTerm;
        const minimumCharacters = getMinSuggestChars();

        setShowSearchHistory(searchTerm.length === 0);
        setValue(searchTerm);

        if (searchTerm.trim().length >= minimumCharacters && !isSameSearchTerm) {
          onChange(searchTerm);
        }

        if (searchTerm.length < 2 && autoSuggestActive) onClear();
      }
    },
    [autoSuggestActive, loading, onChange, onClear, value],
  );

  const handleFocus = useCallback(() => {
    if (!hasFocus) {
      focusInput(true);
      setHasFocus(true);
      setShowSearchHistory(!hasValue);
    }
  }, [focusInput, hasFocus, hasValue]);

  /*
  On first click it will focus and highlight the word,
  On second and after clicks the cursor will appear on position
  */
  const handleClick = useCallback(() => {
    const { current: input } = inputRef;

    if (!hasHighlighted && hasValue) {
      input.select();
      input.focus();

      setHasHighlighted(true);
      return;
    }

    setHasHighlighted(!!hasFocus);
  }, [hasFocus, hasValue, hasHighlighted]);

  const handleBlur = useCallback(() => {
    if (hasFocus) {
      focusInput(false);
      setHasFocus(false);
      setHasHighlighted(false);
      setShowSearchHistory(false);
    }
  }, [focusInput, hasFocus]);

  const handleClear = useCallback(
    event => {
      if (event) event.preventDefault();
      focusInput(true);
      onClear();
      setShowSearchHistory(true);
      setValue('');
      setHasFocus(true);
    },
    [onClear, focusInput],
  );

  const handleKeyDown = useCallback(
    event => {
      if (event.keyCode === KEY_ESCAPE) {
        handleBlur();
      }
    },
    [handleBlur],
  );

  const searchForTerm = useCallback(
    ({ searchTerm, origin }) => {
      const trimmedTerm = searchTerm.trim();
      const queryString = onSubmit({ searchTerm: trimmedTerm, origin });

      if (functionalCookieConsent) {
        saveSearchTerm(trimmedTerm, searchType);
      }

      if (trimmedTerm !== '') {
        onClear();
        window.location.href = url ? url(queryString) : `${SETTINGS.searchPath}?${queryString}`;

        // TODO - Reinstate SPA navigation when needed
        // historyUtils.push(url ? url(queryString) : `${SETTINGS.searchPath}?${queryString}`);
        // slowlyScrollPageVerticallyToTop();
      }

      setValue(trimmedTerm);
      handleBlur();

      if (onSearch) onSearch();
    },
    [functionalCookieConsent, handleBlur, onClear, onSearch, onSubmit, searchType, url],
  );

  const handleSubmit = useCallback(
    event => {
      if (event) event.preventDefault();
      searchForTerm({ searchTerm: value, origin: 'regular' });
    },
    [searchForTerm, value],
  );

  const handleSelectedSearchHistoryItem = useCallback(
    searchTerm => {
      setShowSearchHistory(false);
      setHasFocus(false);
      searchForTerm({ searchTerm, origin: 'search history' });
    },
    [searchForTerm],
  );

  const handleSelectedAutoCompleteItem = useCallback(
    searchTerm => {
      searchForTerm({ searchTerm, origin: 'auto complete' });
    },
    [searchForTerm],
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useOnClickOutside(insideRef, () => {
    handleBlur();
  });

  return {
    handleBlur,
    handleChange,
    handleClear,
    handleClick,
    handleFocus,
    handleKeyDown,
    handleSelectedAutoCompleteItem,
    handleSelectedSearchHistoryItem,
    handleSubmit,
    hasFocus,
    inputRef,
    insideRef,
    setValue,
    showSearchHistory,
    value,
  };
};
