import {
  getCurrentMenuList,
  getMegaMenuRoot,
  getMegaMenuHistory,
} from 'redux/modules/mega-menu/selectors';

import addMegaMenuHistoryItem from 'redux/modules/mega-menu/actions/add-mega-menu-history-item';
import setMegaMenuActiveItem from 'redux/modules/mega-menu/actions/set-mega-menu-active-item';
import setMegaMenuRoot from 'redux/modules/mega-menu/actions/set-mega-menu-root';
import { setMegaMenu } from 'redux/modules/page/actions/set-mega-menu';
import { getCategoryById } from 'redux/modules/taxonomy/selectors';

export default (itemId, isLoggedIn) => (dispatch, getState) => {
  const state = getState();
  const menuRoot = getMegaMenuRoot(state);
  const category = getCategoryById(state, itemId);
  const hasDescendants = !!category.categoryIds?.length;
  const level = getMegaMenuHistory(state).length;
  let name = category?.name;

  if (itemId.split('_ALL').length === 2) {
    const allName = getCategoryById(state, itemId.split('_ALL')[0])?.name;
    name = allName && `View all ${allName}`;
  }

  if (itemId.split('_OFFERS').length === 2) {
    const offerName = getCategoryById(state, itemId.split('_OFFERS')[0])?.name;
    name = offerName && `${offerName} offers`;
  }

  dispatch(setMegaMenu(level, itemId, name));

  if (!hasDescendants) return;

  // preserve active item
  dispatch(addMegaMenuHistoryItem({ activeItem: itemId, menuRoot }));
  // set new menu root
  dispatch(setMegaMenuRoot(itemId));
  // set new active item
  const [newActiveItem] = getCurrentMenuList(getState(), isLoggedIn);
  dispatch(setMegaMenuActiveItem(newActiveItem));
};
