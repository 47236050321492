import React, { useEffect, useState, useCallback, useRef } from 'react';
import { bool, func, string } from 'prop-types';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import { SearchBox } from '@johnlewispartnership/wtr-ingredients/ingredients/SearchBox';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/TextLink';
import SearchHistory from 'components/Search/SearchHistory';
// TODO: MultiSearch should be reintroduced as part of this ticket: https://www.jlpit.com/jira/browse/WPIP-51004
// import MultiSearchModal from 'components/MultiSearch/Modal';
import { useSearchForm } from 'components/Search/SearchForm/use-search-form';
import AutoComplete from 'components/Search/AutoComplete';
import FocusTrap from 'components/FocusTrap';
import { useCookieConsent } from 'contexts/CookieConsent';

import styles from './MobileOverlay.module.scss';

const MobileOverlay = ({
  autoSuggestActive,
  clearValue,
  initialValue,
  loading,
  onChange,
  onClear,
  onClose,
  onSubmit,
  setClearValue,
}) => {
  const { functionalCookiesAccepted } = useCookieConsent();
  const {
    handleChange,
    handleClear,
    handleClick,
    handleFocus,
    handleKeyDown,
    handleSelectedAutoCompleteItem,
    handleSelectedSearchHistoryItem,
    handleSubmit,
    hasFocus,
    insideRef: formRef,
    inputRef,
    setValue,
    showSearchHistory,
    value,
  } = useSearchForm({
    autoSuggestActive,
    functionalCookieConsent: functionalCookiesAccepted,
    initialValue,
    loading,
    onChange,
    onClear,
    onSearch: onClose,
    onSubmit,
  });

  const inputProps = {
    onFocus: handleFocus,
    ref: inputRef,
    id: 'mobileSearchTerm',
    onClick: handleClick,
  };

  const insideRef = useRef();
  const [focusTrap /* , setFocusTrap */] = useState(true);

  useEffect(() => {
    const newValue = clearValue ? setValue('') : setValue(initialValue);
    return newValue;
  }, [clearValue, initialValue, setValue]);

  const lockBodyScroll = useCallback(() => {
    disableBodyScroll(insideRef.current);
  }, [insideRef]);

  // const handleFocusTrap = isOpen => {
  //   if (isOpen) {
  //     setFocusTrap(false);
  //   } else {
  //     onClose(false);
  //   }
  // };

  useEffect(() => {
    if (focusTrap) {
      lockBodyScroll();
    }
    // TODO - reinstate with MultiSearch
    // else {
    //   clearAllBodyScrollLocks();
    // }
  }, [focusTrap, lockBodyScroll]);

  useEffect(() => {
    lockBodyScroll();
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [handleClear, insideRef, lockBodyScroll]);

  const closeModal = e => {
    e.preventDefault();
    e.stopPropagation();
    setClearValue(false);
    onClose(false);
    onClear();
  };

  return (
    <FocusTrap active={focusTrap}>
      <div
        ref={insideRef}
        role="dialog"
        aria-label="Search form"
        className={styles.dialog}
        data-test="mobile-overlay"
      >
        <div className={styles.header}>
          <div className={styles.search}>
            {focusTrap && (
              <SearchBox
                ariaActivedescendant={
                  showSearchHistory ? 'search-history-item' : 'autocomplete-suggestion'
                }
                ariaAutocomplete="list"
                ariaOwns={showSearchHistory ? 'search-history' : 'autocomplete-listbox'}
                focus={hasFocus}
                formRef={formRef}
                id="mobileSearch"
                initialValue={initialValue}
                inputProps={inputProps}
                inputRef={inputRef}
                onChange={handleChange}
                onClearClick={handleClear}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                onSubmit={handleSubmit}
                placeholder="Search groceries..."
                value={value}
              />
            )}
          </div>
          <div className={styles.cancel}>
            <TextLink
              component="button"
              role="button"
              onClick={closeModal}
              data-testid="close-overlay"
            >
              Cancel
            </TextLink>
          </div>
        </div>
        <div className={styles.multisearch}>
          {/* <MultiSearchModal
            hidden={false}
            mobile
            onSearch={() => onClose(false)}
            onToogleModal={handleFocusTrap}
          /> */}
        </div>
        {!value.length && (
          <SearchHistory inputRef={inputRef} onSelectTerm={handleSelectedSearchHistoryItem} />
        )}
        <AutoComplete
          inline
          inputRef={inputRef}
          onClear={onClear}
          onSelectItem={handleSelectedAutoCompleteItem}
        />
      </div>
    </FocusTrap>
  );
};

MobileOverlay.propTypes = {
  autoSuggestActive: bool.isRequired,
  clearValue: bool,
  initialValue: string,
  loading: bool.isRequired,
  onChange: func.isRequired,
  onClear: func.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  setClearValue: func.isRequired,
};

MobileOverlay.defaultProps = {
  clearValue: false,
  initialValue: '',
};

export default MobileOverlay;
