import update from 'immutability-helper';

import {
  FAILED,
  FOOTER_FAILED,
  FOOTER_LOADED,
  FOOTER_LOADING,
  LOADED,
  LOADING,
} from 'redux/modules/taxonomy/actions/types';

import initialState from './initial-state';

const mapSubCatsToFlat = (cat, convertedTaxonomy) => {
  if (cat.childCategories.length) {
    cat.childCategories?.forEach(subCat => {
      // eslint-disable-next-line no-param-reassign
      convertedTaxonomy[subCat.id] = {
        id: subCat.id,
        emptyOfProducts: subCat.emptyOfProducts,
        hiddenInNav: subCat.hiddenInNav,
        name: subCat.shortName,
        ...(subCat.childCategories.length && {
          categoryIds: subCat.childCategories.map(sub => sub.id),
        }),
      };
      mapSubCatsToFlat(subCat, convertedTaxonomy);
    });
  }
};

/* This reducer will be replaced by the one in newIndex afer taxonomyNew is done */
export default function taxonomy(state = initialState(), action = {}) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOADED: {
      const { taxonomyTree, childCategories } = action.result;
      const convertedTaxonomy = {};
      if (!action.flat) {
        childCategories.forEach(category => {
          if (!category.hiddenInNav) {
            convertedTaxonomy[category.id] = {
              id: category.id,
              emptyOfProducts: category.emptyOfProducts,
              hiddenInNav: category.hiddenInNav,
              name: category.shortName,
              ...(category.childCategories.length && {
                categoryIds: category.childCategories.map(subCat => subCat.id),
              }),
            };
            mapSubCatsToFlat(category, convertedTaxonomy);
          }
        });
      }
      return {
        ...state,
        data: update(state.data, {
          $merge: action.flat ? taxonomyTree.taxonomy : convertedTaxonomy,
        }),
        depth: action.depth,
        level: `G${action.depth ? action.depth - 1 : 0}`,
        loaded: true,
        loading: false,
        rootCategories: update(state.rootCategories, {
          $set: action.flat
            ? taxonomyTree.rootCategories
            : childCategories.map(el => ({
                id: el.id,
                name: el.shortName,
                parentId: el.id,
                emptyOfProducts: el.emptyOfProducts,
                hiddenInNav: el.hiddenInNav,
              })),
        }),
      };
    }

    case FAILED:
      return {
        ...state,
        errorResponse: action.error,
        loading: false,
      };
    case FOOTER_LOADING: {
      return {
        ...state,
        footer: {
          ...state.footer,
          loading: true,
        },
      };
    }
    case FOOTER_LOADED: {
      return {
        ...state,
        footer: {
          ...state.footer,
          data: action.result,
          loading: false,
        },
      };
    }
    case FOOTER_FAILED: {
      return {
        ...state,
        footer: {
          ...state.footer,
          data: null,
          errorResponse: action.error,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
}
