import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useAuthentication } from '@johnlewispartnership/wtr-website-authentication/dist/context';
import graphQLService from 'services/graphql';
import { useShoppingContext } from 'contexts/ShoppingContext';
import { getFeatureFlags } from 'utils/featureFlags';
import config from 'config';
import TrolleyEventBus, {
  FINISHED_TROLLEY_UPDATE_EVENT,
  STARTED_TROLLEY_UPDATE_EVENT,
} from 'eventbus/trolley';
import TrolleyContext from './TrolleyContext';
import { FinishedTrolleyUpdateEvent, TrolleyContextValue, TrolleyState } from './types';

const getDefaultState = (loading: boolean) => ({
  slotChangeable: true,
  totalEstimatedCost: 0,
  totalNumberOfItems: 0,
  loading,
});

const TrolleyProvider: FC<{
  children: ReactNode;
  state?: TrolleyState;
}> = ({ children, state }) => {
  const { state: authState } = useAuthentication();
  const { shoppingContext } = useShoppingContext();
  const { identity_enableOAuth2Web: OAuth2Enabled } = getFeatureFlags();
  const isAuthEnabled = config.services.tokenClient.enabled && OAuth2Enabled;

  const shouldRenderLoggedInVersion =
    (isAuthEnabled && authState.hasTokenSession && !authState.initialised) ||
    (authState.initialised && authState.isLoggedIn);

  const incomingTrolleyState =
    state || getDefaultState(authState.hasTokenSession && !authState.initialised);

  const [trolleyState, setTrolleyState] = useState<TrolleyState>(incomingTrolleyState);

  const shouldFetchTrolleyState =
    shouldRenderLoggedInVersion && !!shoppingContext.customerOrderId && !!authState.token;

  const onStartedTrolleyUpdateEvent = useCallback(() => {
    setTrolleyState(currentState => ({
      ...currentState,
      loading: true,
    }));
  }, []);

  const onFinishedTrolleyUpdateEvent = useCallback(
    ({ error, trolley }: FinishedTrolleyUpdateEvent) => {
      setTrolleyState(currentState => ({
        ...currentState,
        loading: false,
        ...(!error && {
          totalEstimatedCost: trolley.totalEstimatedCost,
          totalNumberOfItems: trolley.totalNumberOfItems,
        }),
      }));
    },
    [],
  );

  useEffect(() => {
    const unsubscribeStartedTrolleyUpdateEvent = TrolleyEventBus.subscribe(
      STARTED_TROLLEY_UPDATE_EVENT,
      onStartedTrolleyUpdateEvent,
    );
    const unsubscribeFinishedTrolleyUpdateEvent = TrolleyEventBus.subscribe(
      FINISHED_TROLLEY_UPDATE_EVENT,
      onFinishedTrolleyUpdateEvent,
    );

    return () => {
      unsubscribeStartedTrolleyUpdateEvent();
      unsubscribeFinishedTrolleyUpdateEvent();
    };
  }, [onFinishedTrolleyUpdateEvent, onStartedTrolleyUpdateEvent]);

  const syncTrolley = useCallback(async () => {
    setTrolleyState(currentState => ({
      ...currentState,
      loading: true,
    }));

    const trolleyResponse = await graphQLService.getTrolley(
      shoppingContext.customerOrderId,
      authState.token!.authHeader,
    );
    if (!trolleyResponse.error) {
      setTrolleyState({
        loading: false,
        slotChangeable: trolleyResponse.slotChangeable,
        totalEstimatedCost: trolleyResponse.totalEstimatedCost,
        totalNumberOfItems: trolleyResponse.totalNumberOfItems,
        trolley: trolleyResponse.trolley,
      });
    } else {
      setTrolleyState(currentState => ({
        ...currentState,
        loading: false,
        error: {
          message: `Failed to fetch Trolley: ${trolleyResponse.error?.message || `Unknown error`}`,
        },
      }));
    }
  }, [authState.token, shoppingContext.customerOrderId]);

  useEffect(() => {
    if (shouldFetchTrolleyState) {
      if (!trolleyState.trolley && !trolleyState.error) {
        syncTrolley();
      }
    }
  }, [shouldFetchTrolleyState, syncTrolley, trolleyState.error, trolleyState.trolley]);

  const trolleyContextValue = useMemo<TrolleyContextValue>(
    () => ({
      // TODO add any methods here that modify the state
      state: trolleyState,
    }),
    [trolleyState],
  );

  return <TrolleyContext.Provider value={trolleyContextValue}>{children}</TrolleyContext.Provider>;
};

export default TrolleyProvider;
