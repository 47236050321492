import { urls } from 'constants/urls';
import { isWindowAvailable } from './browser';

export const loginPagePath = () =>
  isWindowAvailable
    ? `${urls.login}?redirect=${encodeURIComponent(
        window.location.pathname + window.location.search,
      )}`
    : urls.login;

// To redirect a customer back to the current page after logging in, we need to pass a `?redirect` query parameter
// For SEO purposes, we do not want to include this `?redirect` query in anchor elements
// To resolve this, we use the normal login route without the `?redirect` query parameter in anchor elements
// We then intercept the click events, and redirect the page to the login route WITH the `?redirect` parameter
// This allows us to solve the SEO issue, and redirect the customer back to the current page post login.
export const handleLoginWithRedirect = (event: React.MouseEvent<HTMLAnchorElement>) => {
  event.preventDefault();
  window.location.href = loginPagePath();
};
