import React from 'react';
import { func } from 'prop-types';
import MiddleMegaMenuExperienceFragment from 'components/MegaMenu/MiddleMegaMenu';
import MenuExperienceFragmentClickHandler from 'cms-components/MenuExperienceFragmentClickHandler/MenuExperienceFragmentClickHandler';
import { thirdColumnMenu } from 'constants/cms-menus';
import { usePreloadedData } from 'contexts/PreloadedData';
import styles from './CMSMenuPanel.module.scss';
import CMSMenuLinksList from '../CMSMenuLinksList';

const CMSMenuPanel = ({ onClose }) => {
  const { preloadedData } = usePreloadedData();

  return (
    <>
      {preloadedData?.middleMegaMenu?.content?.locations?.middleMegaMenu && (
        <nav className={styles.column2}>
          <MenuExperienceFragmentClickHandler
            type="desktop"
            trackingPrefix="MiddleMegaMenu"
            onClose={onClose}
          >
            <MiddleMegaMenuExperienceFragment
              location={preloadedData.middleMegaMenu.content.locations.middleMegaMenu}
            />
          </MenuExperienceFragmentClickHandler>
        </nav>
      )}
      <nav className={styles.column3}>
        <CMSMenuLinksList menus={thirdColumnMenu} />
      </nav>
    </>
  );
};

CMSMenuPanel.propTypes = {
  onClose: func.isRequired,
};

CMSMenuPanel.displayName = 'CMSMenuPanel';

export { CMSMenuPanel };
