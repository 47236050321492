/* eslint-disable no-console */

/**
 * THIS FILE SHOULD NOT BE IMPORTED. WEBPACK WILL TAKE CARE OF LOADING IT IF NEEDED.
 *
 * Client-side logging fallback. Just use the console methods, because:
 *
 * 1. Our logging package is only needed server-side, for ingesting logs
 *    in our desired JSON format into Elastic.
 *
 * 2. Our wtr-website-logger currently breaks when used client-side, due
 *    to Pino bundling Node-specific code. It should be possible to fix
 *    that if we want to, perhaps with a fix to the actual pino repo.
 *    But we don't really want to do this because...
 *
 * 3. ...We don't want to add extra weight to the client-side bundle when
 *    it's not necessary.
 *
 * TODO But this client-side fix should ideally be handled by
 * wtr-website-logger itself if possible, rather than needing
 * each application which uses it to apply this solution...
 */
let loggerConsole;

if (process.env.ENVIRONMENT === 'prod') {
  loggerConsole = {
    debug: () => undefined,
    info: () => undefined,
    warn: () => undefined,
    error: () => undefined,
  };
} else {
  loggerConsole = console;
}

const logger = {
  debug: loggerConsole.debug,
  info: loggerConsole.info,
  warn: loggerConsole.warn,
  error: loggerConsole.error,
  fatal: loggerConsole.error,
};

export { logger };
