import { connect } from 'react-redux';
import { categoryNameToUrl } from 'utils/format';
import { getCategoryById } from 'redux/modules/taxonomy/selectors';
import { getMegaMenuMaxLength } from 'redux/modules/page/selectors/mega-menu';
import MegaMenuLink from 'components/MegaMenu/MegaMenuLink';

export default connect((state, props) => {
  const { categoryId, level } = props;
  const category = getCategoryById(state, categoryId);
  const title = category.name;
  const shortName = category.shortName || categoryNameToUrl(title);

  return {
    additionalClasses: category.additionalClasses || '',
    categoryId,
    emptyOfProducts: category.emptyOfProducts,
    hasDescendants: category.categoryIds?.length && level + 1 < getMegaMenuMaxLength(state),
    hiddenInNav: category.hiddenInNav,
    shortName,
    title,
    url: category.url || `${props.path}/${shortName}`,
  };
})(MegaMenuLink);
