import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';

const getAutoSuggest = state => state.autoSuggest;

const getAutoSuggestResults = createSelector([getAutoSuggest], autoSuggest =>
  get(autoSuggest, 'results', {}),
);

export const getAutoSuggestActive = createSelector([getAutoSuggest], autoSuggest =>
  get(autoSuggest, 'active', false),
);

export const getAutoSuggestSuggestions = createSelector([getAutoSuggestResults], results =>
  get(results, 'terms', []),
);

export const getAutoSuggestSearchTerm = createSelector([getAutoSuggest], autoSuggest =>
  get(autoSuggest, 'searchTerm', null),
);
