/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';
import { useTrolley } from 'contexts/Trolley';
import { UOMId } from 'services/graphql/types';
import { useShoppingContext } from 'contexts/ShoppingContext';
import { useSlotContext } from 'contexts/Slot';
import splitDateAndTimeFromIsoDateTime from 'utils/format';
import MonetateEventBus, { COMMON_EVENTS_LOADED_EVENT } from 'eventbus/monetate';
import { SlotType } from 'services/slotOrchestration/types';

const useMonetateReporter = () => {
  const {
    state: { loading: trolleyLoading, trolley: trolleyState },
  } = useTrolley();
  const { shoppingContext } = useShoppingContext();
  const { slotContext } = useSlotContext();
  const hasBookedSlot =
    slotContext.details?.startDateTime && slotContext.details?.slotType !== SlotType.UNDEFINED;

  useEffect(() => {
    if (!trolleyLoading && !slotContext.loading) {
      const [slotDate = undefined] = slotContext.details
        ? splitDateAndTimeFromIsoDateTime(slotContext.details?.startDateTime)
        : [];
      const monetateEvents: MonetateEvent[] = [
        [
          'addCartRows',
          (trolleyState?.trolleyItems || []).map(trolleyItem => {
            const isWeighted = trolleyItem.quantity.uom !== UOMId.C62;
            return {
              quantity: isWeighted ? '1' : String(trolleyItem.quantity.amount ?? null),
              unitPrice: String(
                isWeighted ? trolleyItem.totalPrice?.amount : trolleyItem.retailPrice?.price.amount,
              ),
              currency: trolleyItem.totalPrice?.currencyCode || null,
              productId: trolleyItem.lineNumber,
            };
          }),
        ],
        [
          'setCustomVariables',
          [
            { name: 'branchId', value: hasBookedSlot ? slotContext.details?.branchId : undefined },
            { name: 'customerId', value: shoppingContext.customerId || -1 },
            // { name: 'marketingPreferencesAcknowledged', value: true }, // TODO: Should be decided and handled by I&A
            {
              name: 'slotDate',
              value: slotDate,
            },
            { name: 'slotType', value: slotContext.details?.slotType || SlotType.UNDEFINED },
            // { name: 'totalOrderCount', value: -1 }, // TODO: Should be decided and handled by I&A
          ],
        ],
      ];
      // Making monetate events available globally
      window.__WTR_COMMON__.monetate = {
        ...window.__WTR_COMMON__.monetate,
        events: monetateEvents,
      };
      // Broadcasting message that the events just changed, so clients can read them from window.__WTR_COMMON__.monetate
      MonetateEventBus.dispatch(COMMON_EVENTS_LOADED_EVENT);
    }
  }, [
    shoppingContext.customerId,
    shoppingContext.defaultBranchId,
    slotContext.details,
    slotContext.loading,
    trolleyLoading,
    trolleyState,
    hasBookedSlot,
  ]);
};

export default useMonetateReporter;
