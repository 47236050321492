import createReducer from 'redux/createReducer';

import { AUTOSUGGEST_CLEAR, AUTOSUGGEST_FULFILLED } from 'redux/modules/auto-suggest/actions/types';

import autoSuggestClear from './auto-suggest-clear';
import autoSuggestFulfilled from './auto-suggest-fulfilled';

const initialState = {
  active: false,
  error: false,
  loading: false,
  results: {
    terms: [],
  },
  searchTerm: '',
};

const actionTypeReducerMap = new Map([
  [AUTOSUGGEST_CLEAR, autoSuggestClear],
  [AUTOSUGGEST_FULFILLED, autoSuggestFulfilled],
]);

export default createReducer(initialState, actionTypeReducerMap);
