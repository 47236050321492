export const cmsElementTypes = {
  CARD: 'card',
  LOCATION: 'location',
  TEXT: 'text',
};

export const cmsStyles = {
  HEADING: 'heading',
  SMALL: 'small',
  SUBTEXT: 'subtext',
  ULTRAWIDEBOLD: 'ultrawide-bold',
};
export const thirdColumnMenu = [
  {
    id: 'cmsmenu-morefromheading',
    name: 'More from <span style="white-space:nowrap">Waitrose &amp; Partners</span>',
    style: 'heading',
  },
  {
    id: 'cmsmenu-asidenewtab',
    name: 'Each website opens in a new tab/window',
    style: 'small',
  },
  {
    card: true,
    href: 'https://www.waitrosecellar.com/?utm_source=waitrose.com&utm_medium=Referral&utm_campaign=megamenu_cellar',
    id: 'cmsmenu-cellar-title',
    name: 'CELLAR',
    newWindow: true,
    style: 'ultrawide-bold',
    subtext: 'Our shop dedicated to wine, fizz and spirits',
  },
  {
    card: true,
    href: 'https://www.waitrosegarden.com/?utm_source=waitrose.com&utm_medium=Referral&utm_campaign=megamenu_garden',
    id: 'cmsmenu-garden-title',
    name: 'GARDEN',
    newWindow: true,
    style: 'ultrawide-bold',
    subtext: 'All your gardening needs - from plants, seeds, tools & furniture',
  },
];
