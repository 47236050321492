import { connect } from 'react-redux';
import { getMenuCategoryById } from 'redux/modules/taxonomy/selectors';
import {
  getMegaMenuActiveLevel,
  getMegaMenuMaxLength,
  getMegaMenuMenus,
  getMegaMenuRoot,
} from 'redux/modules/page/selectors/mega-menu';
import { setMegaMenu } from 'redux/modules/page/actions/set-mega-menu';
import { navigateFromMegaMenu } from 'redux/modules/page/actions/navigate-from-mega-menu';
import buildOffersQueryParam from './helpers/build-offers-query-param-from-id';
import { buildOffersPathFromMenus } from './helpers/build-offers-path-from-menus';
import MenuLinksList from './MenuLinksList';

export default connect(
  (state, props) => {
    const { level } = props;
    const activeLevel = getMegaMenuActiveLevel(state);
    const menus = getMegaMenuMenus(state);
    const menu = menus[level];
    const category = getMenuCategoryById(state, menu.id);
    const activeChildId = menus[level + 1] ? menus[level + 1].id : null;
    const isAncestor = activeLevel > level;
    const isDescendant = activeLevel < level;
    let relationship = 'self';

    if (isAncestor || isDescendant) {
      relationship = isAncestor ? 'ancestor' : 'descendant';
    }

    const offersPath = {
      pathname: buildOffersPathFromMenus(menus),
      search: buildOffersQueryParam(menu.id),
    };

    return {
      activeChildId,
      category,
      menuMaxLength: getMegaMenuMaxLength(state),
      menuRoot: getMegaMenuRoot(state),
      menus,
      offersPath,
      path: menu.path,
      relationship,
      title: category?.name || null,
    };
  },
  {
    navigateFromMegaMenu,
    setMegaMenu,
  },
)(MenuLinksList);
