import { createContext } from 'react';

export type CookieConsentContextType = {
  functionalCookiesAccepted: boolean;
  analyticsCookiesAccepted: boolean;
  advertisingCookiesAccepted: boolean;
  hasConsentCookie: boolean;
};

export const CookieConsentContext = createContext<CookieConsentContextType | undefined>(undefined);
