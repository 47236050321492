import React from 'react';
import { bool, func, string } from 'prop-types';
import { useAuthentication } from '@johnlewispartnership/wtr-website-authentication/dist/context';

import Button from '@johnlewispartnership/wtr-ingredients/dist/Button';
// TODO: Merge this component with ../SignOut.js if possible
const SignOutButton = ({ loggedIn, onBlur, onClick, onFocus, onLogoutUser, width }) => {
  const { state: authState } = useAuthentication();
  return loggedIn && !authState.isSignedOnAsCustomer ? (
    <Button
      data-testid="sign-out-button"
      data-test="signOut"
      label="Sign out"
      onBlur={onBlur}
      onClick={() => {
        if (onClick) {
          onClick();
        }

        onLogoutUser();
      }}
      onFocus={onFocus}
      theme="secondary"
      type="button"
      width={width}
    />
  ) : null;
};

SignOutButton.propTypes = {
  loggedIn: bool.isRequired,
  onBlur: func,
  onClick: func,
  onFocus: func,
  onLogoutUser: func.isRequired,
  width: string,
};

SignOutButton.defaultProps = {
  onBlur: null,
  onClick: null,
  onFocus: null,
  width: undefined,
};

SignOutButton.displayName = 'SignOutButton';

export default SignOutButton;
