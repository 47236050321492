import React from 'react';
import { cmsMenuLinksType } from 'constants/types/cms-menu';
import CMSMenuLink from 'components/MegaMenu/CMSMenuLink';
import styles from './CMSMenuLinksList.module.scss';

export const CMSMenuLinksList = ({ menus }) => (
  <ul
    data-testid="mega-menu-cms-list"
    className={styles.list}
    aria-label="Use tab button to browse."
    role="menu"
  >
    {menus.map(({ card, categoryId, href, id, name, newWindow, style, subtext }) => (
      <li
        key={`${id}`}
        role="menuitem"
        className={`${styles.item} ${style === 'heading' ? styles.heading : ''}`}
      >
        <CMSMenuLink
          card={card}
          id={categoryId}
          name={name}
          newWindow={newWindow}
          styleAs={style}
          subtext={subtext}
          url={href}
        />
      </li>
    ))}
  </ul>
);

CMSMenuLinksList.displayName = 'CMSMenuLinksList';

CMSMenuLinksList.propTypes = {
  menus: cmsMenuLinksType,
};

CMSMenuLinksList.defaultProps = {
  menus: {},
};
