export enum CustomerOrderState {
  AMENDING = 'AMENDING',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  FULFIL = 'FULFIL',
  PAID = 'PAID',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PENDING = 'PENDING',
  PICKED = 'PICKED',
  PLACED = 'PLACED',
  REFUND_PENDING = 'REFUND_PENDING',
}

export type ShoppingContext = {
  customerId: string;
  customerOrderId: string;
  customerOrderState: CustomerOrderState;
  defaultBranchId: string;
};
