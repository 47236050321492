const reducer = (state, action) => {
  const { result: results, searchTerm } = action;

  return {
    ...state,
    active: true,
    loading: false,
    results,
    searchTerm,
  };
};

export default reducer;
