import React from 'react';

import { usePreloadedData } from 'contexts/PreloadedData';

import { ExperienceFragmentLocationData } from 'cms-components/ExperienceFragments/SeasonalMenuExperienceFragment';
import SeasonalMenuPanels from './SeasonalMenuPanels';

type SeasonalMenuProps = {
  isSeasonalMenuOpen: boolean;
  onToggleSeasonalMenu: () => void;
};

type PreloadedSeasonalMenuData = {
  preloadedData?: {
    seasonalMenu?: {
      content: {
        pageTitle: {
          text: string;
        };
        locations: {
          seasonalMenu: ExperienceFragmentLocationData[];
        };
      };
    };
  };
};

export const SeasonalMenu = ({ isSeasonalMenuOpen, onToggleSeasonalMenu }: SeasonalMenuProps) => {
  const { preloadedData }: PreloadedSeasonalMenuData = usePreloadedData();

  const seasonalMenu = preloadedData?.seasonalMenu?.content;
  const seasonalMenuButtonLabel = seasonalMenu?.pageTitle?.text;
  const seasonalMenuContent = seasonalMenu?.locations?.seasonalMenu;

  const shouldShowSeasonalMenu = !!seasonalMenuButtonLabel && !!seasonalMenuContent;

  if (!shouldShowSeasonalMenu) return null;

  return (
    <SeasonalMenuPanels
      isOpen={isSeasonalMenuOpen}
      onToggle={onToggleSeasonalMenu}
      title={seasonalMenuButtonLabel}
      content={seasonalMenuContent}
    />
  );
};

SeasonalMenu.displayName = 'SeasonalMenu';
