export const linkImagesTexts = {
  CELLAR: {
    image: {
      url: 'https://waitrose-prod.scene7.com/is/image/waitroseprod/wine-2?$Waitrose-Image-Preset-95$&wid=200&fit=constrain,0',
    },
    text: 'Explore more than 1,200 beers, wines and spirits',
    ariaLabel:
      'Waitrose Cellar. Explore more than 1,200 beers, wines and spirits. Opens in new window',
    className: 'imgBox cellar',
  },
  GARDEN: {
    image: {
      url: 'https://waitrose-prod.scene7.com/is/image/waitroseprod/garden-1?$Waitrose-Image-Preset-95$&wid=160&fit=constrain,0',
    },
    text: 'All your gardening needs - to plants, seeds, tools & furniture',
    ariaLabel:
      'Waitrose Garden. All your gardening needs - to plants, seeds, tools & furniture. Opens in new window',
    className: 'imgBox garden',
  },
};
