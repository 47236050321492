import React, { FC, useCallback } from 'react';
import Button from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Button';
import { Home } from '@johnlewispartnership/wtr-ingredients/dist/foundations/icons';
import { useAuthentication } from '@johnlewispartnership/wtr-website-authentication/dist/context';
import { getFeatureFlags } from 'utils/featureFlags';
import { logger } from 'logger';
import config from 'config';

import styles from './ReturnToAdmin.module.scss';

export const ReturnToAdmin: FC = () => {
  const { state: authState, logout } = useAuthentication();
  const { identity_enableOAuth2Web: OAuth2Enabled } = getFeatureFlags();
  const isAuthEnabled = config.services.tokenClient.enabled && OAuth2Enabled;
  const returnToAdminUrl = `${config.adminAppUrl}/customer/${authState.customerId}/details`;

  const handleReturnToAdmin = useCallback(async () => {
    try {
      await logout('/');
      window.location.href = returnToAdminUrl;
    } catch (error) {
      logger.error({
        payload: {
          message: 'Return to admin error',
          errorDetails: {
            error: (error as Error).message,
          },
        },
      });
    }
  }, [logout, returnToAdminUrl]);

  return isAuthEnabled && authState.isSignedOnAsCustomer ? (
    <div className={styles.returnToAdmin}>
      <Button
        startIcon={<Home />}
        label="Return to admin"
        onClick={handleReturnToAdmin}
        width="fit"
      />
    </div>
  ) : null;
};
