import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useAuthentication } from '@johnlewispartnership/wtr-website-authentication/dist/context';
import shoppingContextServiceClient, { defaultShoppingContext } from 'services/shoppingContext';
import usePrevious from 'hooks/use-previous';
import {
  ShoppingContextState,
  ShoppingContext,
  type ShoppingContextValue,
} from './ShoppingContext';

const ShoppingContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { state: authState } = useAuthentication();
  const [shoppingContext, setShoppingContext] =
    useState<ShoppingContextState>(defaultShoppingContext);
  const previousAuthInitialised = usePrevious(authState.initialised);
  const authJustGotInitialised = !previousAuthInitialised && authState.initialised;

  useEffect(() => {
    const doEffect = async () => {
      if (authJustGotInitialised && authState.token && !authState.error) {
        const shoppingResult = await shoppingContextServiceClient.getShoppingContext({
          authHeader: authState.token.authHeader,
        });
        setShoppingContext(shoppingResult);
      }
    };

    doEffect();
  }, [authJustGotInitialised, authState.error, authState.token]);

  const providerValue: ShoppingContextValue = useMemo(
    () => ({
      shoppingContext,
      setShoppingContext,
    }),
    [shoppingContext, setShoppingContext],
  );

  return <ShoppingContext.Provider value={providerValue}>{children}</ShoppingContext.Provider>;
};

export default ShoppingContextProvider;
