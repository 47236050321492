import { getSearchQueryFromApiRequest } from 'utils/searchQuery';
import { UPDATE_SEARCH_TERM } from 'redux/modules/search/actions/types';

export const updateSearchTerm =
  ({ searchTerm, origin }) =>
  (dispatch, getState) => {
    dispatch({
      type: UPDATE_SEARCH_TERM,
      searchTerm,
      origin,
    });

    return getSearchQueryFromApiRequest(getState().search.queryParams);
  };
