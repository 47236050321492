import { HttpResponse, ServiceClient } from '@johnlewispartnership/wtr-website-api-client';
import config from 'config';
import { logApiError } from 'logger';
import { Address } from 'services/address/types';
import getApiBaseUrl from 'utils/getApiBaseUrl';

type GetAddressError = {
  message: string;
};

export type GetAddressResult = {
  address: Address | null;
  error?: GetAddressError;
};

class AddressServiceClient extends ServiceClient {
  async getAddressById({
    authHeader,
    addressId,
  }: {
    authHeader: string;
    addressId: string;
  }): Promise<GetAddressResult> {
    const response = await this.httpClient
      .get<Address>(`/addresses/${addressId}`, {
        headers: {
          Authorization: authHeader,
        },
      })
      .catch(e => ({ error: e } as HttpResponse<Address>));

    const address = response.data;

    if (!address || response.error) {
      logApiError({
        payload: {
          service: 'address',
          url: `${getApiBaseUrl(config.services.address.path)}/addresses/${addressId}`,
          message: `Error getting address: ${
            response.error?.message || 'Address missing from response'
          }`,
          duration: response.duration,
          /**
           * // This field has a different format according to our logging standards (e.g.: "200 (OK)" ).
           * It's a string value, which means we can't do queries like "httpStatus > 200 AND httpStatus < 300".
           * We might want to get the standards changed.
           * See logging standards: https://www.jlpit.com/wiki/pages/viewpage.action?spaceKey=WD&title=Waitrose+E-commerce+logging+standards
           */
          httpStatus: `${response.status}`,
          // This field is not documented in the logging standards, but it's a number, so we can use relational operators in our queries.
          httpStatusCode: response.status,
        },
      });
    }

    return {
      address: address || null,
      error: response.error ? { message: response.error.message } : undefined,
    };
  }
}

const addressServiceClient = new AddressServiceClient(getApiBaseUrl(config.services.address.path));

export default addressServiceClient;
