import React from 'react';
import { bool, string, func } from 'prop-types';

import { SearchBox } from '@johnlewispartnership/wtr-ingredients/ingredients/SearchBox';
import AutoComplete from 'components/Search/AutoComplete';
import SearchHistory from 'components/Search/SearchHistory';

import { useSearchForm } from './use-search-form';

// TODO add MultiSearchModal when we implement it

const SearchForm = ({
  autoSuggestActive,
  functionalCookieConsent,
  homepage,
  id,
  initialValue,
  loading,
  placeholder,
  onClear,
  onSubmit,
  onSearch,
  onChange,
}) => {
  const {
    handleChange,
    handleClear,
    handleClick,
    handleFocus,
    handleKeyDown,
    handleSelectedAutoCompleteItem,
    handleSelectedSearchHistoryItem,
    handleSubmit,
    hasFocus,
    inputRef,
    insideRef,
    showSearchHistory,
    value,
  } = useSearchForm({
    autoSuggestActive,
    functionalCookieConsent,
    initialValue,
    loading,
    onChange,
    onClear,
    onSearch,
    onSubmit,
  });

  const inputProps = {
    onFocus: handleFocus,
    ref: inputRef,
    onClick: handleClick,
  };

  return (
    <SearchBox
      ariaActivedescendant={showSearchHistory ? 'search-history-item' : 'autocomplete-suggestion'}
      ariaAutocomplete="list"
      ariaOwns={showSearchHistory ? 'search-history' : 'autocomplete-listbox'}
      focus={hasFocus}
      formRef={insideRef}
      id={id}
      initialValue={initialValue}
      inputProps={inputProps}
      inputRef={inputRef}
      isHomepage={homepage}
      onChange={handleChange}
      onClearClick={handleClear}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      onSubmit={handleSubmit}
      placeholder={placeholder}
      value={value}
    >
      {hasFocus && !showSearchHistory && (
        <AutoComplete
          homepage={homepage}
          inputRef={inputRef}
          onClear={onClear}
          onSelectItem={handleSelectedAutoCompleteItem}
        />
      )}

      {showSearchHistory && (
        <SearchHistory
          inputRef={inputRef}
          homepage={homepage}
          onSelectTerm={handleSelectedSearchHistoryItem}
        />
      )}
    </SearchBox>
  );
};
SearchForm.displayName = 'SearchForm';

SearchForm.propTypes = {
  autoSuggestActive: bool,
  functionalCookieConsent: bool,
  homepage: bool,
  id: string,
  initialValue: string,
  loading: bool.isRequired,
  placeholder: string,
  onClear: func.isRequired,
  onSearch: func,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
};

SearchForm.defaultProps = {
  autoSuggestActive: false,
  functionalCookieConsent: false,
  homepage: false,
  id: undefined,
  initialValue: '',
  onSearch: null,
  placeholder: 'Search...',
};

export default SearchForm;
