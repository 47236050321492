import { useCallback, useContext, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { KEY_DOWN, KEY_ENTER, KEY_LEFT, KEY_RIGHT, KEY_UP } from 'constants/keys';

import { useClientOnlyLayoutEffect } from 'hooks/use-client-only-layout-effect';
import { MenuFocusContext } from './MenuFocusProvider';

export const useMenuFocus = (ref, { id, skip } = {}) => {
  const { current: uid } = useRef(`navitem_${uuidv4()}`);
  const {
    focus,
    focusNext,
    focusPrevious,
    handleLeft,
    handleRight,
    handleEnter,
    register,
    selectedUid,
    unregister,
  } = useContext(MenuFocusContext);

  const outsideProvider = register === null;

  useClientOnlyLayoutEffect(() => {
    if (outsideProvider) return undefined;
    if (!ref.current) return undefined;
    if (!skip) register({ id, ref, uid });
    return () => unregister(uid);
  }, [id, outsideProvider, ref, register, skip, uid, unregister]);

  const handleKeyDown = useCallback(
    event => {
      const { keyCode } = event;
      const keyHandlers = {
        [KEY_DOWN]: () => focusNext(uid),
        [KEY_UP]: () => focusPrevious(uid),
        [KEY_RIGHT]: () => handleRight(uid),
        [KEY_LEFT]: () => handleLeft(uid),
        [KEY_ENTER]: () => handleEnter(uid),
      };

      const keyHandler = keyHandlers[keyCode];

      if (keyHandler) {
        keyHandler();
      }

      event.preventDefault();
    },
    [focusNext, focusPrevious, handleEnter, handleLeft, handleRight, uid],
  );

  const handleClick = useCallback(() => {
    focus(uid);
  }, [focus, uid]);

  const selected = uid === selectedUid;

  return outsideProvider
    ? {
        selected: false,
        tabIndex: '0',
        handleClick: () => {},
        handleKeyDown: () => {},
      }
    : {
        selected,
        tabIndex: selected ? '0' : '-1',
        handleClick,
        handleKeyDown,
      };
};
