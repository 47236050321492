// TODO remove this file and use switch instead as we're already using for other reducers
export interface ReducerAction<Type, Payload = undefined> {
  type: Type;
  payload?: Payload;
}

function createReducer<
  State,
  Type extends string,
  Payload,
  ReducerMap extends
    | readonly (readonly [Type, WtrReducer<State, Payload> | unknown])[]
    | Map<Type, WtrReducer<State, Payload>>,
>(
  initialState: State,
  actionTypeReducerMap: ReducerMap,
): (state?: State, action?: ReducerAction<Type, Payload>) => State {
  const map =
    actionTypeReducerMap instanceof Map
      ? (actionTypeReducerMap as Map<Type, WtrReducer<State, Payload>>)
      : new Map<Type, WtrReducer<State, Payload>>(
          actionTypeReducerMap as Iterable<readonly [Type, WtrReducer<State, Payload>]>,
        );
  // eslint-disable-next-line default-param-last
  return function reducer(state = initialState, action?: ReducerAction<Type, Payload>) {
    if (action?.type) {
      const actualReducer = map.get(action.type);
      if (actualReducer) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return actualReducer(state, action as any); // It is ok to use any in highly generic functions, such as here
      }
    }

    return state;
  };
}

export default createReducer;
