import { useContext } from 'react';
import { SlotContext } from './SlotContext';

const useSlotContext = () => {
  const slotContext = useContext(SlotContext);

  if (slotContext === undefined) {
    throw new Error('useSlotContext must be used within a SlotProvider');
  }

  return slotContext;
};

export default useSlotContext;
